import React from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";

import VideoList from "./pages/VideoList";
import VideoDetail from "./pages/VideoDetail";
import Login from "./pages/Login";

const router = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={VideoList} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/videos" component={VideoList} />
        <Route exact path="/videos/:id/label" component={VideoDetail} />
      </Switch>
    </Router>
  );
};

export default router;
