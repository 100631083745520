import { LabelClass, LabelClassGroup } from "../datamodel/labelClass";
import config from "../config";

type ClassGroupMapping = Record<LabelClassGroup, string[]>;

export enum LabelCategoryTitle {
  PoorQuality = "Poor Quality",
  Lesions = "Lesions",
  OtherDisease = "Other Disease",
  OtherDescriptors = "Other Descriptors",
}

export interface LabelCategory {
  title: LabelCategoryTitle;
  groups: string[][];
}

const labelCategoryInit = [
  {
    title: "Poor Quality",
    groups: [],
  },
  {
    title: "Lesions",
    groups: [],
  },
  {
    title: "Other Disease",
    groups: [],
  },
  {
    title: "Other Descriptors",
    groups: [],
  },
];

const orderClassNames = (names: string[]): string[] =>
  names.sort((a, b) => config.labelClassOrder[a] - config.labelClassOrder[b]);

const createLabelClassCategory = (labelClasses: LabelClass[]): LabelCategory[] => {
  const classGroupMap = labelClasses.reduce((acc, labelClass) => {
    const key = labelClass.classGroup;
    if (acc[key] === undefined) acc[key] = [labelClass.name];
    else acc[key].push(labelClass.name);
    return acc;
  }, {} as ClassGroupMapping);
  return labelCategoryInit.map(labelCategory => {
    if (labelCategory.title === LabelCategoryTitle.PoorQuality) {
      return {
        ...labelCategory,
        groups: [
          ...labelCategory.groups,
          orderClassNames(classGroupMap[LabelClassGroup.PoorQuality]),
        ],
      } as LabelCategory;
    }

    if (labelCategory.title === LabelCategoryTitle.Lesions) {
      return {
        ...labelCategory,
        groups: [...labelCategory.groups, orderClassNames(classGroupMap[LabelClassGroup.Lesions])],
      } as LabelCategory;
    }

    if (labelCategory.title === LabelCategoryTitle.OtherDisease) {
      return {
        ...labelCategory,
        groups: [
          ...labelCategory.groups,
          orderClassNames(classGroupMap[LabelClassGroup.OtherDisease]),
        ],
      } as LabelCategory;
    }

    return {
      ...labelCategory,
      groups: [
        ...labelCategory.groups,
        orderClassNames(classGroupMap[LabelClassGroup.OtherDescriptors]),
      ],
    } as LabelCategory;
  });
};

export default createLabelClassCategory;
