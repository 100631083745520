import * as t from "io-ts";
import { AxiosResponse } from "axios";
import { Video, videoCodec } from "../datamodel/video";
import customAxios from "./customAxios";
import { decodeAxiosResponse } from "./decodeAxiosResponse";

export const listVideos = async (): Promise<AxiosResponse<Video[]>> =>
  decodeAxiosResponse(t.array(videoCodec))(await customAxios.get("/api/videos"));

export const getVideo = async (id: string): Promise<AxiosResponse<Video>> =>
  decodeAxiosResponse(videoCodec)(await customAxios.get(`/api/videos/${id}`));

export const updateVideo = async (video: Video): Promise<AxiosResponse<"">> =>
  await customAxios.put(`/api/videos/${video.id}`, videoCodec.encode(video));
