/** @jsx jsx */
import { jsx } from "theme-ui";
import { useRef } from "react";

import { formatSeconds, timeToSpace, spaceToTime, clickToPositionX } from "../../utils";

const sx = {
  indicator: {
    position: "absolute",
  },
  line: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "stretch",
    ml: "-4px",
    width: "8px",
    height: "100%",
    cursor: "ew-resize",
    "::after": {
      content: "''",
      width: "2px",
      bg: "accent",
      borderWidth: "0 1px",
      borderStyle: "solid",
      borderColor: "background",
    },
  },
  scrubTime: {
    position: "absolute",
    top: 0,
    left: "-4rem",
    width: "8rem",
    bg: "background",
    px: "0.4rem",
    pb: "0.2rem",
    color: "accent",
    fontSize: 2,
    fontWeight: "bold",
    textAlign: "center",
    pointerEvents: "none",
    transition: "left 150ms ease-in",
  },
};

function CurrentTimeIndicator({
  zoom,
  currentTime,
  latestTentativeStartTime,
  duration,
  tracksRef,
  isPlaying,
  isVideoLabeled,
  onPlay,
  isScrubbing,
  onScrub = () => {},
  onScrubbing = () => {},
  ...props
}) {
  const indicatorRef = useRef();
  const scrubTimeRef = useRef();

  const handleMouseDown = e => {
    e.stopPropagation();
    e.preventDefault();
    const wasPlaying = isPlaying;
    onPlay(false);
    const position = clickToPositionX(e, tracksRef.current);
    indicatorRef.current.style.setProperty("left", `${position}px`);
    scrubTimeRef.current.setAttribute("data-time", formatSeconds(spaceToTime(position, zoom)));
    onScrubbing(true);

    const moveHandler = e => {
      e.stopPropagation();
      e.preventDefault();
      requestAnimationFrame(() => {
        let position = clickToPositionX(e, tracksRef.current);
        let time = spaceToTime(position, zoom);
        if (time < 0) {
          time = 0;
          position = timeToSpace(time, zoom);
        } else if (time > duration) {
          time = duration;
          position = timeToSpace(time, zoom);
        }
        if (latestTentativeStartTime && latestTentativeStartTime >= time) {
          time = latestTentativeStartTime + 1;
          position = timeToSpace(time, zoom);
        }
        indicatorRef.current.style.setProperty("left", `${position}px`);
        scrubTimeRef.current.setAttribute("data-time", formatSeconds(time));
        onScrub(time);
      });
    };

    window.addEventListener("mousemove", moveHandler);

    window.addEventListener(
      "mouseup",
      () => {
        window.removeEventListener("mousemove", moveHandler);
        window.requestAnimationFrame(() => indicatorRef.current.style.removeProperty("left"));
        onScrubbing(false);
        wasPlaying && onPlay(true);
      },
      {
        once: true,
      }
    );
  };

  return (
    <div
      ref={indicatorRef}
      sx={{
        ...sx.indicator,
        ...(isScrubbing ? {} : { left: `${timeToSpace(zoom, currentTime)}px` }),
      }}
      {...props}
    >
      <span
        ref={scrubTimeRef}
        sx={{
          ...sx.scrubTime,
          ...(currentTime < spaceToTime(20, zoom)
            ? {
                left: "-2px",
                textAlign: "left",
              }
            : {}),
          ...(isScrubbing
            ? {
                "::before": {
                  content: "attr(data-time)",
                  pointerEvents: "none",
                },
              }
            : {}),
        }}
      >
        {!isScrubbing && formatSeconds(currentTime)}
      </span>
      <div sx={sx.line} onMouseDown={handleMouseDown} />
    </div>
  );
}

export default CurrentTimeIndicator;
