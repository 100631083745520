/** @jsx jsx */
import { jsx } from "theme-ui";
import { forwardRef, useMemo, useState } from "react";

import Axis from "./Axis";
import Track from "./Track";

import { SORT_FUNCTION } from "../../sort";
import { spaceToTime, timeToSpace, clickToPositionX } from "../../utils";

const sx = {
  tracks: {
    position: "relative",
    minHeight: "7.2rem",
    bg: "background",
  },
  axis: {
    height: "3.2rem",
  },
  track: {
    ":not(:last-of-type)": {
      mb: "4px",
    },
  },
};

function groupBy(objectArray, property) {
  return objectArray.reduce((acc, obj) => {
    let key = obj[property];
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(obj);
    return acc;
  }, {});
}

const Tracks = forwardRef(
  (
    {
      zoom,
      currentTime,
      duration,
      sort,
      labels = [],
      labelClassMap = {},
      labelClassCategory,
      poorQualityClasses = [],
      tentativeLabels = [],
      isScrubbing,
      isScrubbingLabelStartEndTime,
      isVideoLabeled,
      furthestViewedTime,
      onTentativeLabelStart = () => {},
      onTentativeLabelEnd = () => {},
      onLabelSelect = () => {},
      onLabelClassChange = () => {},
      onLabelDelete = () => {},
      onLabelStartTimeChange = () => {},
      onLabelEndTimeChange = () => {},
      onScrubbingLabelStartEndTime = () => {},
      onPlay = () => {},
      onScrub = () => {},
      ...props
    },
    ref
  ) => {
    const [labelStartClickDelta, setLabelStartClickDelta] = useState(null);
    const [labelEndClickDelta, setLabelEndClickDelta] = useState(null);

    const [hoveredLabel, setHoveredLabel] = useState(null);
    const [isTrackActionHovered, setIsTrackActionHovered] = useState(false);

    const [poorQualityLabels, nonPoorQualityLabels] = useMemo(() => {
      const allLabels = [...labels, ...tentativeLabels];

      const poorQualityLabels = allLabels.filter(label => poorQualityClasses.includes(label.class));

      const otherLabels = allLabels.filter(label => !poorQualityClasses.includes(label.class));

      const nonPoorQualityLabels = groupBy(otherLabels, "class");

      return [poorQualityLabels, nonPoorQualityLabels];
    }, [tentativeLabels, labels, poorQualityClasses]);

    const labelKeys = useMemo(() => {
      return Object.entries(nonPoorQualityLabels)
        .sort(SORT_FUNCTION[sort])
        .map(([labelClass]) => labelClass);
    }, [nonPoorQualityLabels, sort]);

    const timelineHasTentativeLabels = tentativeLabels?.length > 0;

    const handleClick = e => {
      const position = clickToPositionX(e, e.currentTarget);
      const time = spaceToTime(position, zoom);
      onScrub(time);
    };

    const handleScrubbingLabelStartTime = clickDelta => {
      if (clickDelta) {
        onScrubbingLabelStartEndTime(true);
        setLabelStartClickDelta(clickDelta);
      } else {
        onScrubbingLabelStartEndTime(false);
        setLabelStartClickDelta(null);
      }
    };

    const handleScrubbingLabelEndTime = clickDelta => {
      if (clickDelta) {
        onScrubbingLabelStartEndTime(true);
        setLabelEndClickDelta(clickDelta);
      } else {
        onScrubbingLabelStartEndTime(false);
        setLabelEndClickDelta(null);
      }
    };

    return (
      <div
        ref={ref}
        sx={{
          ...sx.tracks,
          width: `${timeToSpace(zoom, duration)}px`,
          cursor: isScrubbing ? "inherit" : "pointer",
        }}
        onClick={handleClick}
        {...props}
      >
        <Axis
          sx={sx.axis}
          zoom={zoom}
          duration={duration}
          currentTime={currentTime}
          furthestViewedTime={isVideoLabeled ? null : furthestViewedTime}
          tracksRef={ref}
          hideHoverIndicator={isScrubbing || isTrackActionHovered}
          isScrubbingLabelStartWithClickDelta={labelStartClickDelta}
          isScrubbingLabelEndWithClickDelta={labelEndClickDelta}
          hoveredLabel={hoveredLabel}
        />
        <Track
          sx={{ ...sx.track, zIndex: 2 }}
          key="Poor Quality"
          name="Poor Quality"
          labels={poorQualityLabels}
          labelClassMap={labelClassMap}
          labelClasses={poorQualityClasses}
          labelClassCategory={labelClassCategory}
          poorQualitySegments={poorQualityLabels}
          isPoorQuality
          timelineHasTentativeLabels={timelineHasTentativeLabels}
          zoom={zoom}
          isScrubbing={isScrubbing}
          isScrubbingLabelStartEndTime={isScrubbingLabelStartEndTime}
          isVideoLabeled={isVideoLabeled}
          currentTime={currentTime}
          duration={duration}
          tracksRef={ref}
          onTentativeLabelStart={onTentativeLabelStart}
          onTentativeLabelEnd={onTentativeLabelEnd}
          onTrackActionHover={setIsTrackActionHovered}
          onLabelHover={setHoveredLabel}
          onLabelSelect={onLabelSelect}
          onLabelClassChange={onLabelClassChange}
          onLabelDelete={onLabelDelete}
          onLabelStartTimeChange={onLabelStartTimeChange}
          onLabelEndTimeChange={onLabelEndTimeChange}
          onScrubbingLabelStartTime={handleScrubbingLabelStartTime}
          onScrubbingLabelEndTime={handleScrubbingLabelEndTime}
          onScrub={onScrub}
        />
        {labelKeys.map(labelClass => (
          <Track
            key={labelClass}
            sx={sx.track}
            name={labelClass}
            labels={nonPoorQualityLabels[labelClass]}
            labelClassMap={labelClassMap}
            labelClassCategory={labelClassCategory}
            labelClasses={[labelClass]}
            poorQualitySegments={poorQualityLabels}
            timelineHasTentativeLabels={timelineHasTentativeLabels}
            zoom={zoom}
            isScrubbing={isScrubbing}
            isScrubbingLabelStartEndTime={isScrubbingLabelStartEndTime}
            isVideoLabeled={isVideoLabeled}
            currentTime={currentTime}
            duration={duration}
            tracksRef={ref}
            onTentativeLabelStart={onTentativeLabelStart}
            onTentativeLabelEnd={onTentativeLabelEnd}
            onTrackActionHover={setIsTrackActionHovered}
            onLabelHover={setHoveredLabel}
            onLabelSelect={onLabelSelect}
            onLabelClassChange={onLabelClassChange}
            onLabelDelete={onLabelDelete}
            onLabelStartTimeChange={onLabelStartTimeChange}
            onLabelEndTimeChange={onLabelEndTimeChange}
            onScrubbingLabelStartTime={handleScrubbingLabelStartTime}
            onScrubbingLabelEndTime={handleScrubbingLabelEndTime}
            onScrub={onScrub}
          />
        ))}
      </div>
    );
  }
);

export default Tracks;
