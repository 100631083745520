/** @jsx jsx */
import { jsx, Button } from "theme-ui";
import { useRef, forwardRef, Fragment } from "react";

import Label from "./Label";

import { ReactComponent as StartIcon } from "../../img/plus.svg";
import { ReactComponent as EndIcon } from "../../img/stop.svg";

import { timeToSpace } from "../../utils";

const sx = {
  track: {
    position: "relative",
    width: "100%",
    height: "4rem",
    bg: "backgroundGray",
  },
  poorQualitySegment: {
    position: "absolute",
    top: 0,
    bottom: 0,
    bg: "poorQualityBg",
  },
  labelClass: {
    position: "fixed",
    color: "text",
    fontSize: 2,
    lineHeight: "4rem",
    pointerEvents: "none",
  },
  label: {
    zIndex: 1,
  },
  activeLabel: {
    bg: "labelActive",
  },
  actions: {
    zIndex: 0,
    position: "absolute",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
    minHeight: "100%",
    maxWidth: "30rem",
    py: "0.4rem",
    bg: "backgroundGray",
    whiteSpace: "nowrap",
    transition: "opacity 150ms",
  },
  startIcon: { mr: 1 },
  startButton: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    ml: 1,
    p: 0,
    px: "0.4rem",
    appearance: "none",
    boxShadow: 0,
    bg: "transparent",
    color: "text",
    height: "100%",
    fontSize: 2,
    ":hover": {
      color: "text",
      textDecoration: "underline",
    },
    ":not(:first-of-type)": {
      display: "none",
      ml: 2,
    },
  },
  endButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    p: 0,
    appearance: "none",
    boxShadow: 0,
    bg: "transparent",
    color: "stop",
    borderStyle: "solid",
    borderColor: "labelTentative",
    borderWidth: "1px 1px 1px 0",
    height: "3.2rem",
    width: "4rem",
    fontSize: 4,
    ":hover": {
      borderColor: "stop",
    },
  },
};

const Actions = forwardRef(
  (
    {
      show,
      name,
      labelClasses,
      tentativeLabel,
      onTentativeLabelStart,
      onTentativeLabelEnd,
      ...props
    },
    ref
  ) => {
    return (
      <div
        ref={ref}
        sx={{
          ...sx.actions,
          ...(show
            ? {
                opacity: 1,
                pointerEvents: "auto",
              }
            : {
                opacity: 0,
                pointerEvents: "none",
              }),
          ...(!tentativeLabel
            ? {
                ":hover button:first-of-type": {
                  display: "none",
                },
                ":hover button:not(:first-of-type)": {
                  display: "flex",
                },
              }
            : {}),
        }}
        {...props}
      >
        {tentativeLabel ? (
          <Fragment>
            <Button
              sx={sx.endButton}
              onClick={e => {
                e.stopPropagation();
                onTentativeLabelEnd(tentativeLabel);
              }}
              title="Stop this label"
              aria-label="Stop this label"
            >
              <EndIcon width="1em" height="1em" />
            </Button>
          </Fragment>
        ) : (
          <Fragment>
            <Button sx={{ ...sx.startButton, opacity: 0.3 }}>
              <StartIcon width="1em" height="1em" sx={sx.startIcon} />
              {name}
            </Button>
            {labelClasses.map((labelClass, i) => (
              <Button
                key={labelClass}
                sx={sx.startButton}
                onClick={e => {
                  e.stopPropagation();
                  onTentativeLabelStart(labelClass);
                }}
                title="Add this label"
                aria-label="Add this label"
              >
                {labelClass}
              </Button>
            ))}
          </Fragment>
        )}
      </div>
    );
  }
);

function Track({
  name: _name,
  labels: _labels = [],
  labelClasses = [],
  labelClassMap = {},
  labelClassCategory,
  poorQualitySegments = [],
  isPoorQuality = false,
  timelineHasTentativeLabels,
  zoom,
  isScrubbing,
  isScrubbingLabelStartEndTime,
  isVideoLabeled,
  currentTime,
  duration,
  tracksRef,
  onTentativeLabelStart = () => {},
  onTentativeLabelEnd = () => {},
  onTrackActionHover = () => {},
  onLabelHover = () => {},
  onLabelSelect = () => {},
  onLabelClassChange = () => {},
  onLabelDelete = () => {},
  onLabelStartTimeChange = () => {},
  onLabelEndTimeChange = () => {},
  onScrubbingLabelStartTime = () => {},
  onScrubbingLabelEndTime = () => {},
  onScrub = () => {},
  ...props
}) {
  const actionsRef = useRef();

  const name = _name || labelClasses[0];

  const labels = _labels.sort((a, b) => a.start_time - b.start_time);

  const tentativeLabel = labels.find(
    label => label.end_time === null || label.end_time === undefined
  );

  const activeLabel = labels.find(
    ({ start_time, end_time }) => start_time <= currentTime && currentTime <= end_time
  );

  const showActions = !isScrubbing && !isScrubbingLabelStartEndTime && !activeLabel;

  const handleTrackActionMouseEnter = e => {
    e.stopPropagation();
    e.preventDefault();
    onTrackActionHover(true);
  };

  const handleTrackActionMouseLeave = e => {
    e.stopPropagation();
    e.preventDefault();
    onTrackActionHover(false);
  };

  return (
    <div
      sx={{
        ...sx.track,
        ...(isPoorQuality ? { bg: "poorQualityBg" } : {}),
      }}
      {...props}
    >
      {!isPoorQuality &&
        poorQualitySegments.map(({ start_time, end_time }) => (
          <div
            key={start_time}
            sx={{
              ...sx.poorQualitySegment,
              left: `${timeToSpace(start_time, zoom)}px`,
              width: `${timeToSpace((end_time || currentTime) - start_time, zoom)}px`,
            }}
          />
        ))}
      {labels.map((label, i) => (
        <Label
          key={label.start_time}
          sx={{
            ...sx.label,
            ...(label === activeLabel ? sx.activeLabel : {}),
          }}
          label={label}
          labelClassMap={labelClassMap}
          labelClassCategory={labelClassCategory}
          currentTime={currentTime}
          duration={duration}
          zoom={zoom}
          timelineHasTentativeLabels={timelineHasTentativeLabels}
          tracksRef={tracksRef}
          isVideoLabeled={isVideoLabeled}
          minStartTime={i === 0 ? 0 : labels[i - 1].end_time}
          maxEndTime={i === labels.length - 1 ? duration : labels[i + 1].start_time}
          onHover={onLabelHover}
          onSelect={onLabelSelect}
          onClassChange={onLabelClassChange}
          onDelete={onLabelDelete}
          onStartTimeChange={onLabelStartTimeChange}
          onEndTimeChange={onLabelEndTimeChange}
          onScrubbingStartTime={onScrubbingLabelStartTime}
          onScrubbingEndTime={onScrubbingLabelEndTime}
          onScrub={onScrub}
        />
      ))}
      {!isVideoLabeled && (
        <Actions
          ref={actionsRef}
          sx={{
            left: `${timeToSpace(currentTime, zoom)}px`,
            ...(isPoorQuality ? { bg: "poorQualityBg" } : {}),
          }}
          show={showActions}
          name={name}
          labelClasses={labelClasses}
          tentativeLabel={tentativeLabel}
          onTentativeLabelStart={onTentativeLabelStart}
          onTentativeLabelEnd={onTentativeLabelEnd}
          onMouseEnter={handleTrackActionMouseEnter}
          onMouseLeave={handleTrackActionMouseLeave}
        />
      )}
    </div>
  );
}

export default Track;
