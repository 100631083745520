/** @jsx jsx */
import { jsx } from "theme-ui";
import { useEffect, useRef } from "react";

import { formatSeconds, timeToSpace, spaceToTime, clickToPositionX } from "../../utils";

const sx = {
  indicator: {
    position: "absolute",
    top: 0,
    zIndex: 1,
    height: "100%",
    borderWidth: "0 1px",
    borderStyle: "dashed",
    borderColor: "accent",
    pointerEvents: "none",
  },
  start: {
    position: "absolute",
    right: "calc(100% + 0.8rem)",
    bottom: "calc(100%)",
    textAlign: "right",
    fontSize: 1,
    lineHeight: 1,
    color: "accent",
    pointerEvents: "none",
  },
  end: {
    position: "absolute",
    left: "calc(100% + 0.8rem)",
    bottom: "calc(100%)",
    textAlign: "left",
    fontSize: 1,
    lineHeight: 1,
    color: "accent",
    pointerEvents: "none",
  },
  scrubTime: {
    "::before": {
      content: "attr(data-time)",
    },
  },
};

function HoveredLabelTimeIndicator({
  label,
  zoom,
  duration,
  isScrubbingLabelStartWithClickDelta,
  isScrubbingLabelEndWithClickDelta,
  tracksRef,
  ...props
}) {
  const { start_time, end_time } = label;

  const indicatorRef = useRef();
  const scrubRef = useRef();

  const clickDelta = isScrubbingLabelStartWithClickDelta || isScrubbingLabelEndWithClickDelta;

  useEffect(() => {
    if (!clickDelta) {
      return;
    }

    indicatorRef.current.style.setProperty("left", `${indicatorRef.current.offsetLeft}px`);
    indicatorRef.current.style.setProperty("width", `${indicatorRef.current.offsetWidth}px`);
    if (scrubRef.current.setAttribute) {
      scrubRef.current.setAttribute(
        "data-time",
        formatSeconds(isScrubbingLabelStartWithClickDelta ? start_time : end_time)
      );
    }

    const moveHandler = e => {
      requestAnimationFrame(() => {
        const mousePosition = clickToPositionX(e, tracksRef.current);
        let position = mousePosition - clickDelta;
        let time = spaceToTime(position, zoom);
        let left, width;
        if (isScrubbingLabelStartWithClickDelta) {
          if (time < 0) {
            time = 0;
            position = timeToSpace(time, zoom);
          } else if (time >= end_time) {
            time = end_time - 1;
            position = timeToSpace(time, zoom);
          }
          left = position;
          width = timeToSpace(end_time - time, zoom);
        } else if (isScrubbingLabelEndWithClickDelta) {
          if (time > duration) {
            time = duration;
            position = timeToSpace(time, zoom);
          } else if (time <= start_time) {
            time = start_time + 1;
            position = timeToSpace(time, zoom);
          }
          left = timeToSpace(start_time, zoom);
          width = timeToSpace(time - start_time, zoom);
        }
        if (scrubRef.current.setAttribute) {
          scrubRef.current.setAttribute("data-time", formatSeconds(time));
        }
        if (indicatorRef.current.style) {
          indicatorRef.current.style.setProperty("left", `${left}px`);
          indicatorRef.current.style.setProperty("width", `${width}px`);
        }
      });
    };

    window.addEventListener("mousemove", moveHandler);
    window.addEventListener(
      "mouseup",
      () => {
        window.removeEventListener("mousemove", moveHandler);
      },
      {
        once: true,
      }
    );
  }, [
    clickDelta,
    duration,
    isScrubbingLabelStartWithClickDelta,
    isScrubbingLabelEndWithClickDelta,
    start_time,
    end_time,
    tracksRef,
    zoom,
  ]);

  if (!end_time) {
    return null;
  }

  return (
    <div
      ref={indicatorRef}
      sx={{
        ...sx.indicator,
        left: timeToSpace(start_time, zoom),
        width: timeToSpace(end_time - start_time, zoom),
      }}
      {...props}
    >
      <span sx={sx.start}>
        {isScrubbingLabelStartWithClickDelta ? (
          <span sx={sx.scrubTime} ref={scrubRef} />
        ) : (
          formatSeconds(start_time)
        )}
      </span>
      <span sx={sx.end}>
        {isScrubbingLabelEndWithClickDelta ? (
          <span sx={sx.scrubTime} ref={scrubRef} />
        ) : (
          formatSeconds(end_time)
        )}
      </span>
    </div>
  );
}

export default HoveredLabelTimeIndicator;
