/** @jsx jsx */
import { jsx, Flex, Button, IconButton } from "theme-ui";

import { ReactComponent as CloseIcon } from "../../img/close.svg";
import { ReactComponent as StartIcon } from "../../img/plus-circle.svg";
import { ReactComponent as EndIcon } from "../../img/stop.svg";
import { ReactComponent as CutIcon } from "../../img/cut.svg";
import config from "../../config";

const VIEWPORT_SHORT = config.viewportShort;

const sx = {
  labelPicker: {
    position: "relative",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    bg: "background",
    pb: 2,
    px: 1,
    borderWidth: "3px 0 0",
    borderStyle: "solid",
    borderColor: "accent",
    overflowY: "auto",
    scrollBehavior: "smooth",
    WebkitOverflowScrolling: "touch",
    [VIEWPORT_SHORT]: {
      flexWrap: "wrap",
      pt: 3,
    },
  },
  stopAll: {
    position: "absolute",
    top: "1.6rem",
    right: "6.4rem",
    fontSize: 2,
    lineHeight: "2rem",
    opacity: 0.8,
    cursor: "pointer",
    ":hover": {
      opacity: 1,
    },
    [VIEWPORT_SHORT]: {
      top: "0.4rem",
      right: 4,
      zIndex: 111111,
      bg: "background",
      opacity: 0.9,
      px: 1,
      py: "0.4rem",
      lineHeight: 1,
      border: "2px solid",
      borderColor: "divider",
    },
  },
  close: {
    position: "absolute",
    top: "1.2rem",
    right: "1.2rem",
    fontSize: "2rem",
    cursor: "pointer",
    [VIEWPORT_SHORT]: {
      top: 0,
      right: 0,
      bg: "background",
    },
  },
  category: {
    flex: 1,
    mx: 1,
    px: 2,
    [VIEWPORT_SHORT]: {
      maxWidth: "30%",
      px: 0,
    },
  },
  title: {
    mb: 4,
    py: 2,
    borderBottom: "4px solid",
    borderColor: "divider",
    lineHeight: 1,
    fontSize: 5,
    fontWeight: "normal",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    [VIEWPORT_SHORT]: {
      my: 1,
      py: 1,
      fontSize: 1,
      fontWeight: "bold",
      borderBottomWidth: "2px",
    },
  },
  group: {
    mb: 2,
  },
  button: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    borderRadius: 1,
    mb: 1,
    fontSize: 3,
    textAlign: "left",
    textTransform: "capitalize",
    ":focus": {
      outlineWidth: "2px",
      outlineStyle: "solid",
      outlineColor: "divider",
    },
    ":disabled": {
      cursor: "not-allowed",
      opacity: 0.4,
    },
    ":not(:disabled):hover": {
      transform: "scaleX(1.02)",
    },
    [VIEWPORT_SHORT]: {
      fontSize: 0,
      fontWeight: "bold",
      pl: 1,
      pr: "0.4rem",
    },
  },
  buttonStart: {
    bg: "backgroundGray",
    color: "text",
  },
  buttonEnd: {
    bg: "accent",
    color: "background",
  },
};

function LabelButton({ labelClass, label, onStart, onEnd, onChangeEnd, disabled, ...props }) {
  const isTentative = label && !label.end_time;
  const Icon = isTentative ? EndIcon : label ? CutIcon : StartIcon;
  const title = isTentative
    ? "Stop this label"
    : label
    ? "Truncate this label"
    : "Start this label";

  const handleClick = () => {
    if (isTentative) {
      onEnd(label);
      return;
    }
    if (label) {
      onChangeEnd(label);
      return;
    }
    onStart(labelClass);
  };

  return (
    <Button
      sx={{
        ...sx.button,
        ...(isTentative || label ? sx.buttonEnd : sx.buttonStart),
      }}
      onClick={handleClick}
      title={title}
      disabled={disabled}
      {...props}
    >
      {labelClass}
      {!disabled && <Icon width="1.2em" height="1.2em" sx={{ flex: "none" }} />}
    </Button>
  );
}

function LabelPicker({
  currentTime,
  labels = [],
  labelClassCategory = [],
  poorQualityClasses = [],
  tentativeLabels = [],
  onCancel = () => {},
  onTentativeLabelStart = () => {},
  onTentativeLabelEnd = () => {},
  onTentativeLabelEndAll = () => {},
  onLabelChangeEnd = () => {},
  ...props
}) {
  const coincidentLabels = labels.filter(
    label => label.start_time <= currentTime && label.end_time >= currentTime
  );

  const inPoorQualitySegment =
    coincidentLabels.some(label => poorQualityClasses.includes(label.class)) ||
    tentativeLabels.some(label => poorQualityClasses.includes(label.class));

  return (
    <Flex sx={sx.labelPicker} {...props}>
      <IconButton sx={sx.close} onClick={onCancel}>
        <CloseIcon width="1em" height="1em" />
      </IconButton>
      {tentativeLabels?.length > 0 && (
        <Button sx={sx.stopAll} variant="text" onClick={onTentativeLabelEndAll}>
          Stop all
        </Button>
      )}
      {labelClassCategory.map(({ title, groups }) => (
        <div key={title} sx={sx.category}>
          <h3
            sx={{
              ...sx.title,
              ...(title === "Poor Quality" ? { borderColor: "poorQualityBg" } : {}),
            }}
          >
            {title}
          </h3>
          {groups.map((group, i) => (
            <div key={i} sx={sx.group}>
              {group.map((labelClass, j) => {
                const label =
                  tentativeLabels.find(label => label.class === labelClass) ||
                  coincidentLabels.find(label => label.class === labelClass);
                return (
                  <LabelButton
                    key={labelClass}
                    labelClass={labelClass}
                    label={label}
                    onStart={onTentativeLabelStart}
                    onEnd={onTentativeLabelEnd}
                    onChangeEnd={onLabelChangeEnd}
                    autoFocus={title === "Poor Quality" && j === 0}
                    disabled={
                      inPoorQualitySegment && !label && poorQualityClasses.includes(labelClass)
                    }
                  />
                );
              })}
            </div>
          ))}
        </div>
      ))}
    </Flex>
  );
}

export default LabelPicker;
