/** @jsx jsx */
import { jsx, Grid, Flex, Button } from "theme-ui";
import { useState, useEffect, useRef, Fragment } from "react";
import { useHotkeys } from "react-hotkeys-hook";

import Tracks from "./Tracks";
import LabelPicker from "./LabelPicker";
import Sort from "./Sort";
import CurrentTimeIndicator from "./CurrentTimeIndicator";

import { ReactComponent as StartIcon } from "../../img/plus.svg";

import { SORT_OPTIONS } from "../../constants";
import config from "../../config";

const AUTOSCROLL_TIMEOUT_MS = 1500;

const VIEWPORT_SHORT = config.viewportShort;

const sx = {
  timeline: {
    position: "relative",
    flexDirection: "column",
    width: "100%",
    maxWidth: "100%",
    overflowY: "auto",
  },
  actions: {
    flex: "none",
    gridTemplateColumns: "minmax(5.6rem, 1fr) 64rem 2.4rem minmax(36rem, 45rem) minmax(4rem, 1fr)",
    gridTemplateRows: "5.6rem",
    gap: 0,
    gridTemplateAreas: '"  .  toggle  .  sort  sort  "',
    width: "100%",
    height: "5.6rem",
    bg: "background",
    mb: 3,
    pt: 1,
    [VIEWPORT_SHORT]: {
      display: "flex",
      flexFlow: "row nowrap",
      justifyContent: "flex-start",
      alignItems: "center",
      pl: 3,
    },
  },
  toggleLayerPickerContainer: {
    gridArea: "toggle",
    flexDirection: "row",
    justifyContent: "center",
    width: "640px",
    mr: 3,
    ml: "auto",
    [VIEWPORT_SHORT]: {
      width: "auto",
      mx: 0,
    },
  },
  toggleLayerPicker: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    fontWeight: "bold",
    bg: "accent",
    [VIEWPORT_SHORT]: {
      fontSize: 3,
    },
  },
  sort: {
    mr: 3,
    ml: "auto",
    gridArea: "sort",
  },
  scroll: {
    flex: "auto",
    position: "relative",
    width: "100%",
    maxWidth: "100%",
    pt: 2,
    pb: 3,
    overflowX: "auto",
    WebkitOverflowScrolling: "touch",
  },
  tracks: {
    height: "auto",
  },
  currentTimeIndicator: {
    top: 0,
  },
  labelPicker: {
    position: "fixed",
    mt: 1,
    bottom: 0,
    left: 0,
    width: "100%",
    zIndex: 2000,
    [VIEWPORT_SHORT]: {
      mt: 0,
      top: 0,
      left: "640px",
      right: 0,
      height: "100%",
      width: "auto",
    },
  },
};

function countNonPoorQualityClasses(labels, poorQualityClasses) {
  const classCounts = labels
    .filter(label => !poorQualityClasses.includes(label.class))
    .reduce((classes, label) => {
      if (label.class in classes) {
        classes[label.class]++;
      } else {
        classes[label.class] = 1;
      }
      return classes;
    }, {});
  return Object.keys(classCounts).length;
}

function Timeline({
  currentTime,
  duration,
  labels = [],
  labelClassMap = {},
  labelClassCategory,
  poorQualityClasses = [],
  tentativeLabels = [],
  latestTentativeStartTime,
  furthestViewedTime,
  isPlaying,
  isVideoLabeled,
  isEscOccupied,
  onTentativeLabelStart = () => {},
  onTentativeLabelEnd = () => {},
  onLabelChangeEnd = () => {},
  onLabelClassChange = () => {},
  onLabelDelete = () => {},
  onLabelStartTimeChange = () => {},
  onLabelEndTimeChange = () => {},
  onPlay = () => {},
  onScrub = () => {},
  ...props
}) {
  const [zoom] = useState(10);

  const scrollRef = useRef();
  const tracksRef = useRef();

  const autoScrollTimerRef = useRef();
  const [autoScroll, setAutoScroll] = useState(false);
  useEffect(() => {
    if (!autoScroll) {
      return;
    }
    const scrollLeft = zoom * currentTime - scrollRef.current.clientWidth / 2;
    scrollRef.current.scrollTo({
      left: scrollLeft,
      behavior: Math.abs(scrollLeft - scrollRef.current.scrollLeft) > 20 ? "smooth" : "auto",
    });
  }, [autoScroll, currentTime, zoom]);

  const [isScrubbing, setIsScrubbing] = useState(false);
  const [isScrubbingLabelStartEndTime, setIsScrubbingLabelStartEndTime] = useState(false);

  const [sort, setSort] = useState(SORT_OPTIONS.FIRST_APPEARANCE);

  const [showLabelPicker, setShowLabelPicker] = useState(false);

  const showSort =
    countNonPoorQualityClasses([...labels, ...tentativeLabels], poorQualityClasses) > 1;

  const hasTentativeLabels = tentativeLabels && tentativeLabels.length > 0;

  const handleMouseLeave = e => {
    autoScrollTimerRef.current = window.setTimeout(
      () => setAutoScroll(true),
      AUTOSCROLL_TIMEOUT_MS
    );
  };

  const handleMouseEnter = e => {
    window.clearTimeout(autoScrollTimerRef.current);
    setAutoScroll(false);
  };

  const handleTentativeLabelStart = label => {
    onTentativeLabelStart(label);
    setShowLabelPicker(false);
    onPlay(true);
  };

  const handleTentativeLabelEnd = label => {
    onTentativeLabelEnd(label);
    setShowLabelPicker(false);
    onPlay(true);
  };

  const handleTentativeLabelEndAll = () => {
    tentativeLabels.forEach(label => onTentativeLabelEnd(label));
    setShowLabelPicker(false);
    onPlay(true);
  };

  const handleLabelChangeEnd = labelClass => {
    onLabelChangeEnd(labelClass);
    setShowLabelPicker(false);
    onPlay(true);
  };

  const [wasPlaying, setWasPlaying] = useState();

  const toggleLayerPicker = () => {
    if (showLabelPicker) {
      setShowLabelPicker(false);
      wasPlaying && onPlay(true);
    } else {
      setWasPlaying(isPlaying);
      onPlay(false);
      setShowLabelPicker(true);
    }
  };

  useHotkeys(
    "esc",
    e => {
      if (isVideoLabeled) {
        return;
      }
      if (!isEscOccupied) {
        e.stopPropagation();
        e.preventDefault();
        toggleLayerPicker();
      }
    },
    [isVideoLabeled, showLabelPicker, isPlaying, wasPlaying, isEscOccupied]
  );

  return (
    <Flex
      sx={{
        ...sx.timeline,
        ...(isScrubbing ? { cursor: "ew-resize" } : {}),
      }}
      {...props}
    >
      <Grid sx={sx.actions} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <Flex sx={sx.toggleLayerPickerContainer}>
          {!isVideoLabeled && !showLabelPicker && (
            <Button sx={sx.toggleLayerPicker} onClick={toggleLayerPicker} title="Esc">
              {hasTentativeLabels ? (
                "Start/stop labels…"
              ) : (
                <Fragment>
                  <StartIcon width="1em" height="1em" sx={{ mr: 1 }} />
                  Start a label here…
                </Fragment>
              )}
            </Button>
          )}
        </Flex>
        <Sort
          sx={{
            ...sx.sort,
            opacity: showSort ? 1 : 0,
            pointerEvents: showSort ? "auto" : "none",
          }}
          value={sort}
          onChange={e => setSort(e.target.value)}
        />
      </Grid>
      <div
        ref={scrollRef}
        sx={{
          ...sx.scroll,
          overflowX: showLabelPicker ? "hidden" : "auto",
          ...(isScrubbing ? { cursor: "ew-resize" } : {}),
        }}
        {...props}
      >
        <Tracks
          sx={sx.tracks}
          ref={tracksRef}
          zoom={zoom}
          currentTime={currentTime}
          duration={duration}
          furthestViewedTime={furthestViewedTime}
          isVideoLabeled={isVideoLabeled}
          sort={sort}
          labels={labels}
          labelClassMap={labelClassMap}
          labelClassCategory={labelClassCategory}
          poorQualityClasses={poorQualityClasses}
          tentativeLabels={tentativeLabels}
          onTentativeLabelStart={onTentativeLabelStart}
          onTentativeLabelEnd={onTentativeLabelEnd}
          onLabelSelect={label => onScrub(label.start_time)}
          onLabelClassChange={onLabelClassChange}
          onLabelDelete={onLabelDelete}
          onLabelStartTimeChange={onLabelStartTimeChange}
          onLabelEndTimeChange={onLabelEndTimeChange}
          onScrubbingLabelStartEndTime={setIsScrubbingLabelStartEndTime}
          onPlay={onPlay}
          onScrub={onScrub}
          isScrubbing={isScrubbing}
          isScrubbingLabelStartEndTime={isScrubbingLabelStartEndTime}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        />
        {!isScrubbingLabelStartEndTime && (
          <CurrentTimeIndicator
            sx={{
              ...sx.currentTimeIndicator,
              height: `${tracksRef.current?.offsetHeight + 16}px`,
            }}
            zoom={zoom}
            currentTime={currentTime}
            latestTentativeStartTime={latestTentativeStartTime}
            duration={duration}
            tracksRef={tracksRef}
            isPlaying={isPlaying}
            isVideoLabeled={isVideoLabeled}
            onPlay={onPlay}
            onScrub={onScrub}
            isScrubbing={isScrubbing}
            onScrubbing={setIsScrubbing}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          />
        )}
      </div>
      {showLabelPicker && (
        <LabelPicker
          sx={{
            ...sx.labelPicker,
            top: `${tracksRef.current.getBoundingClientRect().top}px`,
          }}
          currentTime={currentTime}
          labels={labels}
          labelClassCategory={labelClassCategory}
          poorQualityClasses={poorQualityClasses}
          tentativeLabels={tentativeLabels}
          onCancel={() => setShowLabelPicker(false)}
          onTentativeLabelStart={handleTentativeLabelStart}
          onTentativeLabelEnd={handleTentativeLabelEnd}
          onTentativeLabelEndAll={handleTentativeLabelEndAll}
          onLabelChangeEnd={handleLabelChangeEnd}
        />
      )}
    </Flex>
  );
}

export default Timeline;
