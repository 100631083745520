import { pipe } from "fp-ts/es6/function";
import { map, Option } from "fp-ts/es6/Option";
import { findFirst } from "fp-ts/es6/Array";

import { LabelCreate, LabelPrime } from "../datamodel/label";
import { LabelClass } from "../datamodel/labelClass";

const transformLabelPrimeToLabelCreate = (
  label: LabelPrime,
  labelClasses: LabelClass[]
): Option<LabelCreate> =>
  pipe(
    findFirst((lc: LabelClass) => lc.name.toUpperCase() === label.class.toUpperCase())(
      labelClasses
    ),
    map(
      lc =>
        ({
          classId: lc.id,
          endTime: Math.round(label.end_time * 1000),
          startTime: Math.round(label.start_time * 1000),
        } as LabelCreate)
    )
  );

export default transformLabelPrimeToLabelCreate;
