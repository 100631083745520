import React, { useEffect, useState } from "react";
import useRouter from "use-react-router";
import { none, some, Option, fold, map, option } from "fp-ts/es6/Option";
import { pipe } from "fp-ts/es6/function";
import { sequenceT } from "fp-ts/es6/Apply";
import { findFirst } from "fp-ts/es6/Array";
import { RouteComponentProps } from "react-router-dom";
import Cookies from "js-cookie";

import DetailPage from "./DetailPage";
import transformDomain from "../../lib/transformDomain";
import { getVideo, updateVideo } from "../../http/video";
import { Video, VideoPrime } from "../../datamodel/video";
import { LabelClass } from "../../datamodel/labelClass";
import FullScreenLoader from "../../components/FullScreenLoader";
import transformVideoToPrime from "../../lib/transformVideoToPrime";
import transformVideoPrimeToVideo from "../../lib/transformVideoPrimeToVideo";
import { listLabelClasses } from "../../http/labelClass";
import { LabelCreate, LabelPrime } from "../../datamodel/label";
import { listLabels, postLabel, updateLabel, deleteLabel } from "../../http/label";
import transformLabelToLabelPrime from "../../lib/transformLabelToLabelPrime";
import createLabelClassMap from "../../lib/createLabelClassMap";
import createLabelClassCategory, {
  LabelCategory,
  LabelCategoryTitle,
} from "../../lib/createLabelClassCategory";

type Route = RouteComponentProps<{
  id: string;
}>;

const VideoDetail = () => {
  const [videoOpt, setVideoOpt] = useState<Option<VideoPrime>>(none);
  const [labelClassesOpt, setLabelClassesOpt] = useState<Option<LabelClass[]>>(none);
  const [labelPrimesOpt, setLabelPrimesOpt] = useState<Option<LabelPrime[]>>(none);
  const [labelClassMapOpt, setLabelClassMapOpt] = useState<Option<Record<string, string>>>(none);
  const [labelClassCategoryOpt, setLabelClassCategoryOpt] = useState<Option<LabelCategory[]>>(none);
  const [poorQualityClassesOpt, setPoorQualityClassesOpt] = useState<Option<string[]>>(none);
  const [isDirty, setIsDirty] = useState<boolean>(false);

  const {
    match: {
      params: { id },
    },
    history,
  }: Route = useRouter();

  useEffect(() => {
    const fetchVideo = async () => {
      const { data: video } = await getVideo(id);
      setVideoOpt(some(transformVideoToPrime(video)));
    };
    fetchVideo();
  }, [id]);

  useEffect(() => {
    const fetchLabelClasses = async () => {
      const { data: labelClasses } = await listLabelClasses();
      const categories = createLabelClassCategory(labelClasses);
      const poorQualityNames = pipe(
        findFirst((category: LabelCategory) => category.title === LabelCategoryTitle.PoorQuality)(
          categories
        ),
        map(category => category.groups[0])
      );
      setLabelClassesOpt(some(labelClasses));
      setLabelClassMapOpt(some(createLabelClassMap(labelClasses)));

      setLabelClassCategoryOpt(some(categories));
      setPoorQualityClassesOpt(poorQualityNames);
    };
    fetchLabelClasses();
  }, []);

  useEffect(() => {
    const fetchLabels = async (labelClasses: LabelClass[]) => {
      const { data: labels } = await listLabels(id);
      setLabelPrimesOpt(
        some(
          labels.reduce(
            (acc, label) =>
              pipe(
                transformLabelToLabelPrime(label, labelClasses),
                fold(
                  () => acc,
                  l => [...acc, l]
                )
              ),
            [] as LabelPrime[]
          )
        )
      );
    };

    pipe(
      labelClassesOpt,
      fold(() => {}, fetchLabels)
    );
  }, [id, labelClassesOpt, isDirty]);

  const handleVideoList = (): void => {
    history.push("/videos");
  };

  const handleSignOut = (): void => {
    const url = new URL(window.location.href);
    Cookies.remove("sso-token", {
      domain: transformDomain(url),
      path: "/",
    });
    history.push("/login");
  };

  const handleVideoUpdate = (video: VideoPrime, isDirty: boolean = true) => {
    const updateVideoStatus = async (vid: Video) => {
      try {
        await updateVideo(vid);
        if (isDirty) setVideoOpt(some(transformVideoToPrime(vid)));
        return Promise.resolve();
      } catch (err) {
        console.error(err);
        return Promise.reject();
      }
    };
    return updateVideoStatus(transformVideoPrimeToVideo(video));
  };

  const handleCreateLabel = (label: Option<LabelCreate>) => {
    pipe(
      label,
      fold(
        () => {},
        async l => {
          try {
            await postLabel(id, l);
            setIsDirty(!isDirty);
          } catch (err) {
            console.error(err);
          }
        }
      )
    );
  };

  const handleUpdateLabel = (labelId: string | null, label: Option<LabelCreate>) => {
    if (labelId) {
      pipe(
        label,
        fold(
          () => {},
          async l => {
            try {
              await updateLabel(id, labelId, l);
              setIsDirty(!isDirty);
            } catch (err) {
              console.error(err);
            }
          }
        )
      );
    }
  };

  const handleDeleteLabel = async (labelId: string | null) => {
    if (labelId) {
      try {
        await deleteLabel(id, labelId);
        setIsDirty(!isDirty);
      } catch (err) {
        console.error(err);
      }
    }
  };

  return pipe(
    sequenceT(option)(
      videoOpt,
      labelClassesOpt,
      labelPrimesOpt,
      labelClassMapOpt,
      labelClassCategoryOpt,
      poorQualityClassesOpt
    ),
    fold(
      () => <FullScreenLoader />,
      ([
        video,
        labelClasses,
        existingLabels,
        labelClassMap,
        labelClassCategory,
        poorQualityClasses,
      ]) => (
        <DetailPage
          video={video}
          labelClasses={labelClasses}
          labelClassMap={labelClassMap}
          labelClassCategory={labelClassCategory}
          poorQualityClasses={poorQualityClasses}
          existingLabels={existingLabels}
          handleCreateLabel={handleCreateLabel}
          handleUpdateLabel={handleUpdateLabel}
          handleDeleteLabel={handleDeleteLabel}
          handleVideoUpdate={handleVideoUpdate}
          handleVideoList={handleVideoList}
          handleSignOut={handleSignOut}
        />
      )
    )
  );
};

export default VideoDetail;
