/** @jsx jsx */
import { jsx } from "theme-ui";

const track = {
  height: "6px",
  cursor: "pointer",
  backgroundColor: "var(--trackBackgroundColor)",
  boxShadow: "0 0 2px var(--trackShadowColor)",
  borderRadius: "50px",
};

const thumb = {
  background: "var(--thumbColor)",
  borderWidth: "var(--thumbBorderWidth)",
  borderStyle: "solid",
  borderColor: "var(--thumbBorderColor)",
  borderRadius: "50px",
  cursor: "grab",
  appearance: "none",
};

const sx = {
  range: {
    "--trackBackgroundColor": theme => theme.colors.background,
    "--trackShadowColor": "#666",
    "--focusColor": "#aaa",
    "--trackLoadedColor": "#444",
    "--trackPlayedColor": theme => theme.colors.accent,
    "--playbackRateColor": theme => theme.colors.accent,
    "--thumbColor": theme => theme.colors.accent,
    "--thumbBorderColor": theme => theme.colors.background,
    "--thumbBorderWidth": "3px",
    appearance: "none",
    bg: "transparent",
    "::-webkit-slider-runnable-track": track,
    "::-moz-range-track": track,
    "::-webkit-slider-thumb": {
      mt: "-5px",
      width: "16px",
      height: "16px",
      ...thumb,
    },
    "::-moz-range-thumb": {
      width: "12px",
      height: "12px",
      ...thumb,
    },
    ":focus": {
      outline: "none",
    },
    ":focus::-webkit-slider-runnable-track": {
      boxShadow: "0 0 2px var(--focusColor)",
    },
    ":focus::-moz-range-track": {
      boxShadow: "0 0 2px var(--focusColor)",
    },
  },
};

function Slider({ backgroundImage, ...props }) {
  const trackStyles = backgroundImage ? { ...track, backgroundImage } : track;
  return (
    <input
      type="range"
      sx={{
        ...sx.range,
        "::-webkit-slider-runnable-track": trackStyles,
        "::-moz-range-track": trackStyles,
      }}
      {...props}
    />
  );
}

export default Slider;
