/** @jsx jsx */
import { jsx, Flex } from "theme-ui";

import Dialog from "./Dialog";

const sx = {
  dialog: {
    width: "90%",
    maxWidth: "64rem",
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
    mt: 3,
    mb: 7,
    fontSize: 3,
  },
  keys: {
    flex: "none",
    width: "24rem",
    textAlign: "right",
  },
  key: {
    display: "inline-block",
    border: "1px solid",
    borderRadius: "4px",
    mx: 1,
    py: 1,
    px: 2,
  },
  action: {
    flex: "auto",
    ml: 4,
  },
};

function HotkeysDialog({ onClose = () => {}, ...props }) {
  return (
    <Dialog title="Keyboard shortcuts" onCancel={onClose} sx={sx.dialog} {...props}>
      <Flex sx={sx.row}>
        <span sx={sx.keys}>
          <span sx={sx.key}>Space</span>
        </span>
        <span sx={sx.action}>Play/pause video</span>
      </Flex>
      <Flex sx={sx.row}>
        <span sx={sx.keys}>
          <span sx={sx.key}>ESC</span>
        </span>
        <span sx={sx.action}>Toggle label picker</span>
      </Flex>
      <Flex sx={sx.row}>
        <span sx={sx.keys}>
          <span sx={sx.key}>&larr;</span>
          <span sx={sx.key}>&rarr;</span>
        </span>
        <span sx={sx.action}>Jump back/forward 1 second</span>
      </Flex>
      <Flex sx={sx.row}>
        <span sx={sx.keys}>
          <span sx={sx.key}>Shift</span>+<span sx={sx.key}>&larr;</span>
          <span sx={sx.key}>&rarr;</span>
        </span>
        <span sx={sx.action}>Jump back/forward 5 seconds</span>
      </Flex>
      <Flex sx={sx.row}>
        <span sx={sx.keys}>
          <span sx={sx.key}>?</span>
        </span>
        <span sx={sx.action}>Open this panel</span>
      </Flex>
    </Dialog>
  );
}

export default HotkeysDialog;
