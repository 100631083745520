/** @jsx jsx */
import { jsx, Flex } from "theme-ui";

import { ReactComponent as SortIcon } from "../../img/sort-tracks.svg";

import { SORT_OPTIONS } from "../../constants";

const sx = {
  sort: {
    position: "relative",
    flexDirection: "row",
    alignItems: "center",
    border: "1px solid",
    borderColor: "divider",
    borderRadius: 2,
    fontSize: 3,
    lineHeight: 2,
    transition: "opacity 150ms",
  },
  sortIcon: {
    position: "absolute",
    flex: "none",
    ml: 2,
  },
  sortSelect: {
    appearance: "none",
    flex: "auto",
    py: "0.4rem",
    pl: 6,
    pr: 2,
    border: "none",
    fontSize: "inherit",
    lineHeight: "inherit",
    color: "inherit",
    bg: "transparent",
    cursor: "pointer",
    option: {
      color: "black",
    },
  },
};

function Sort({ value, onChange, ...props }) {
  return (
    <Flex sx={sx.sort} {...props}>
      <SortIcon sx={sx.sortIcon} width="1em" height="1em" />
      <select sx={sx.sortSelect} value={value} onChange={onChange}>
        {Object.keys(SORT_OPTIONS).map(option => (
          <option value={SORT_OPTIONS[option]} key={SORT_OPTIONS[option]}>
            {SORT_OPTIONS[option]}
          </option>
        ))}
      </select>
    </Flex>
  );
}

export default Sort;
