/** @jsx jsx */
import { jsx, Flex, IconButton } from "theme-ui";
import { useRef, useState, Fragment } from "react";

import { ReactComponent as ActionsIcon } from "../../img/ellipsis.svg";
import { ReactComponent as ClassChangeIcon } from "../../img/edit.svg";
import { ReactComponent as DeleteIcon } from "../../img/delete.svg";
import { ReactComponent as GripIcon } from "../../img/grip.svg";

import { timeToSpace, spaceToTime, clickToPositionX } from "../../utils";
import { VideoContextConsumer } from "../data/VideoContext";

const sx = {
  container: {
    position: "absolute",
    my: "0.4rem",
    height: "3.2rem",
    lineHeight: "3.2rem",
    fontSize: 2,
    whiteSpace: "nowrap",
    bg: "label",
    ":hover": {
      bg: "labelHover",
    },
  },
  label: {
    position: "relative",
    zIndex: 1,
    flexDirection: "row",
    minWidth: "0.4rem",
    width: "100%",
    maxWidth: "100%",
    color: "labelTentative",
    overflow: "hidden",
    textOverflow: "ellipsis",
    cursor: "pointer",
    mixBlendMode: "difference",
  },
  labelText: {
    height: "100%",
    px: "1.2rem",
    fontWeight: "bold",
    pointerEvents: "none",
  },
  tentativeLabelText: {
    position: "absolute",
    top: 0,
    right: 0,
    width: "auto",
    minWidth: "100%",
  },
  grips: {
    position: "absolute",
    zIndex: 2,
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "stretch",
    color: "background",
    transition: "opacity 66ms",
  },
  gripButton: {
    m: 0,
    p: 0,
    width: "1.2rem",
    cursor: "ew-resize",
    opacity: 0.7,
    ":hover": {
      opacity: 1,
    },
  },
  actions: {
    position: "absolute",
    left: "100%",
    height: "100%",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "stretch",
    px: "0.4rem",
    minWidth: "4rem",
    border: "1px solid",
    borderColor: "labelHover",
    bg: "backgroundGray",
    fontSize: "1.6rem",
    color: "labelHover",
    transition: "opacity 66ms",
  },
  actionsIcon: {
    alignSelf: "center",
  },
  classChange: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    mx: 0,
    fontSize: "1.4rem",
    cursor: "pointer",
    ":hover": {
      color: "accent",
    },
  },
  classChangeSelect: {
    position: "absolute",
    top: 0,
    left: 0,
    appearance: "none",
    width: "100%",
    height: "100%",
    overflow: "hidden",
    bg: "transparent",
    color: "transparent",
    border: "none",
    p: 0,
    cursor: "pointer",
    option: {
      color: "black",
    },
  },
  classChangeIcon: {
    mx: 1,
  },
  deleteButton: {
    mx: 0,
    fontSize: "1.4rem",
    cursor: "pointer",
    ":hover": {
      color: "accent",
    },
  },
};

function Label({
  label,
  labelClassMap,
  labelClassCategory,
  currentTime,
  duration,
  zoom,
  timelineHasTentativeLabels,
  tracksRef,
  isVideoLabeled,
  minStartTime = 0,
  maxEndTime,
  onHover = () => {},
  onSelect = () => {},
  onClassChange = () => {},
  onDelete = () => {},
  onStartTimeChange = () => {},
  onEndTimeChange = () => {},
  onScrubbingStartTime = () => {},
  onScrubbingEndTime = () => {},
  onScrub = () => {},
  ...props
}) {
  const labelRef = useRef();
  const labelTextRef = useRef();

  const [showActions, setShowActions] = useState(false);
  const [expandActions, setExpandActions] = useState(false);
  const [isScrubbing, setIsScrubbing] = useState(false);

  const { class: label_class, start_time, end_time } = label;

  const isTentative = end_time === null || end_time === undefined;

  const left = `${zoom * start_time}px`;

  if (isTentative) {
    // This should probably be handled in state
    label.tentative_end_time = Math.min(currentTime, maxEndTime - 1);
  }

  const width = `${zoom * ((isTentative ? label.tentative_end_time : end_time) - start_time)}px`;

  const handleClick = e => {
    e.preventDefault();
    e.stopPropagation();
    !isScrubbing && onSelect(label);
  };

  const handleMouseEnter = () => {
    if (isTentative) {
      return;
    }
    setShowActions(true);
    onHover(label);
  };

  const handleMouseLeave = () => {
    if (isTentative) {
      return;
    }
    setShowActions(false);
    !isScrubbing && onHover(null);
  };

  const handleClassChange = e => {
    e.preventDefault();
    e.stopPropagation();
    onHover(null);
    onClassChange(label, e.target.value);
  };

  const handleDelete = e => {
    e.preventDefault();
    e.stopPropagation();
    onHover(null);
    onDelete(label);
  };

  const handleStartTimeMouseDown = e => {
    e.stopPropagation();
    e.preventDefault();

    const clickPosition = clickToPositionX(e, tracksRef.current);
    const clickDelta = clickPosition - labelRef.current.offsetLeft;
    const position = clickPosition - clickDelta;
    labelRef.current.style.setProperty("left", `${position}px`);
    setIsScrubbing(true);
    onScrubbingStartTime(clickDelta);
    onScrub(start_time);

    const moveHandler = e => {
      e.stopPropagation();
      e.preventDefault();
      requestAnimationFrame(() => {
        const clickPosition = clickToPositionX(e, tracksRef.current);
        let position = clickPosition - clickDelta;
        let time = spaceToTime(position, zoom);
        if (time < minStartTime) {
          time = minStartTime + 1;
          position = timeToSpace(time, zoom);
        } else if (time >= end_time) {
          time = end_time - 1;
          position = timeToSpace(time, zoom);
        }
        const width = timeToSpace(end_time - time, zoom);
        labelRef.current.style.setProperty("left", `${position}px`);
        labelRef.current.style.setProperty("width", `${width}px`);
        onScrub(time);
      });
    };

    window.addEventListener("mousemove", moveHandler);

    window.addEventListener(
      "mouseup",
      e => {
        window.removeEventListener("mousemove", moveHandler);
        window.requestAnimationFrame(() => {
          labelRef.current.style.removeProperty("left");
          labelRef.current.style.removeProperty("width");
          const clickPosition = clickToPositionX(e, tracksRef.current);
          const position = clickPosition - clickDelta;
          let time = spaceToTime(position, zoom);
          if (time < 0) {
            time = 0;
          } else if (time >= end_time) {
            time = end_time - 1;
          }
          setIsScrubbing(false);
          onScrubbingStartTime(false);
          onStartTimeChange(label, time);
        });
      },
      {
        once: true,
      }
    );
  };

  const handleEndTimeMouseDown = e => {
    e.stopPropagation();
    e.preventDefault();

    const clickPosition = clickToPositionX(e, tracksRef.current);
    const clickDelta = clickPosition - (labelRef.current.offsetLeft + labelRef.current.offsetWidth);
    const position = clickPosition - clickDelta;
    const left = labelRef.current.offsetLeft;
    const width = position - left;
    labelRef.current.style.setProperty("left", `${left}px`);
    labelRef.current.style.setProperty("width", `${width}px`);
    setIsScrubbing(true);
    onScrubbingEndTime(clickDelta);
    onScrub(end_time);

    const moveHandler = e => {
      e.stopPropagation();
      e.preventDefault();
      requestAnimationFrame(() => {
        const clickPosition = clickToPositionX(e, tracksRef.current);
        let position = clickPosition - clickDelta;
        let time = spaceToTime(position, zoom);
        if (time > (maxEndTime || duration)) {
          time = (maxEndTime || duration) - 1;
          position = timeToSpace(time, zoom);
        } else if (time <= start_time) {
          time = start_time + 1;
          position = timeToSpace(time, zoom);
        }
        const width = timeToSpace(time - start_time, zoom);
        labelRef.current.style.setProperty("width", `${width}px`);
        onScrub(time);
      });
    };

    window.addEventListener("mousemove", moveHandler);

    window.addEventListener(
      "mouseup",
      e => {
        window.removeEventListener("mousemove", moveHandler);
        window.requestAnimationFrame(() => {
          labelRef.current.style.removeProperty("left");
          labelRef.current.style.removeProperty("width");
          const clickPosition = clickToPositionX(e, tracksRef.current);
          const position = clickPosition - clickDelta;
          let time = spaceToTime(position, zoom);
          if (time > duration) {
            time = duration;
          } else if (time <= start_time) {
            time = start_time + 1;
          }
          setIsScrubbing(false);
          onScrubbingEndTime(false);
          onEndTimeChange(label, time);
        });
      },
      {
        once: true,
      }
    );
  };

  return (
    <Flex
      ref={labelRef}
      sx={{
        ...sx.container,
        ...(isScrubbing
          ? {
              bg: "labelHover",
            }
          : { left, width }),
        ...(isTentative ? { bg: "labelTentative" } : {}),
      }}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      {...props}
    >
      <div
        sx={{
          ...sx.label,
          overflow:
            isTentative &&
            minStartTime < start_time - spaceToTime(labelTextRef.current?.offsetWidth, zoom)
              ? "visible"
              : "hidden",
        }}
      >
        <span
          ref={labelTextRef}
          sx={{
            ...sx.labelText,
            ...(isTentative ? sx.tentativeLabelText : {}),
          }}
        >
          {label_class}
        </span>
      </div>
      <VideoContextConsumer>
        {isPlaying =>
          !isVideoLabeled &&
          !timelineHasTentativeLabels &&
          !isPlaying && (
            <Fragment>
              <Flex
                sx={{
                  ...sx.grips,
                  opacity: showActions ? 0.3 : 0,
                  pointerEvents: showActions ? "auto" : "none",
                }}
              >
                <IconButton sx={sx.gripButton} onMouseDown={handleStartTimeMouseDown}>
                  <GripIcon
                    width="1em"
                    height="1em"
                    title="Change start time"
                    aria-label="Change start time"
                  />
                </IconButton>
                <IconButton sx={sx.gripButton} onMouseDown={handleEndTimeMouseDown}>
                  <GripIcon
                    width="1em"
                    height="1em"
                    title="Change end time"
                    aria-label="Change end time"
                  />
                </IconButton>
              </Flex>
              <Flex
                sx={{
                  ...sx.actions,
                  opacity: showActions && !isScrubbing ? 1 : 0,
                  pointerEvents: showActions && !isScrubbing ? "auto" : "none",
                }}
                onMouseEnter={() => setExpandActions(true)}
                onMouseLeave={() => setExpandActions(false)}
              >
                {!expandActions && <ActionsIcon sx={sx.actionsIcon} width="1em" height="1em" />}
                {expandActions && (
                  <Fragment>
                    <label sx={sx.classChange} onClick={e => e.stopPropagation()}>
                      <select
                        sx={sx.classChangeSelect}
                        title="Change type"
                        aria-label="Change type"
                        defaultValue={label_class}
                        onChange={handleClassChange}
                      >
                        {labelClassCategory.map((category, index) => (
                          <optgroup label={category.title} key={index}>
                            {category.groups[0].map((labelClass, idx) => (
                              <option key={idx} value={labelClass}>
                                {labelClass}
                              </option>
                            ))}
                          </optgroup>
                        ))}
                      </select>
                      <ClassChangeIcon sx={sx.classChangeIcon} width="1em" height="1em" />
                    </label>
                    <IconButton sx={sx.deleteButton}>
                      <DeleteIcon
                        width="1em"
                        height="1em"
                        title="Delete"
                        aria-label="Delete"
                        onClick={handleDelete}
                      />
                    </IconButton>
                  </Fragment>
                )}
              </Flex>
            </Fragment>
          )
        }
      </VideoContextConsumer>
    </Flex>
  );
}

export default Label;
