import dayjs from "dayjs";

export function formatSeconds(nSeconds = 0) {
  const minutes = Math.floor(nSeconds / 60);
  const seconds = Math.round(nSeconds % 60);
  return `${minutes}:${seconds.toString().padStart(2, "0")}`;
}

export function formatDate(date = Date.now()) {
  return dayjs(date).format("ddd MMM D, YYYY");
}

export function timeToSpace(time, zoom) {
  return time * zoom;
}

export function spaceToTime(position, zoom) {
  return position / zoom;
}

export function clickToPositionX(clickEvent, tracksEl) {
  return (
    clickEvent.clientX -
    tracksEl.offsetParent.getBoundingClientRect().x +
    tracksEl.offsetParent.scrollLeft
  );
}
