/** @jsx jsx */
import { jsx, Grid, Button } from "theme-ui";
import { useState } from "react";

import AccountMenu from "../../components/AccountMenu";
import { formatSeconds, formatDate } from "../../utils";
import { STATUS, VIDEO_FIELDS } from "../../constants";

import { ReactComponent as Logo } from "../../img/logo.svg";
import { ReactComponent as SortIcon } from "../../img/sort.svg";
import { ReactComponent as UnlabelledIcon } from "../../img/circle.svg";
import { ReactComponent as InProgressIcon } from "../../img/clock.svg";
import { ReactComponent as LabelledIcon } from "../../img/check-circle.svg";
import config from "../../config";

const sx = {
  header: {
    gridTemplateColumns: "1fr 2fr 1fr",
  },
  logo: {
    mr: "auto",
    height: "10rem",
    color: "text",
  },
  title: {
    m: 0,
    fontWeight: 200,
    fontSize: 7,
    lineHeight: "11rem",
    textAlign: "center",
  },
  menu: {
    mt: 2,
    ml: "auto",
    mr: 1,
    mb: "auto",
  },
  table: {
    mt: 8,
    mx: "auto",
    borderSpacing: 0,
    width: "64rem",
    maxWidth: "64rem",
  },
  th: {
    py: 2,
    px: 3,
    borderBottom: "1px solid",
    borderColor: "border",
  },
  button: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    pr: 3,
    pl: 1,
    bg: "transparent",
    color: "inherit",
    fontWeight: "normal",
    whiteSpace: "nowrap",
  },
  sort: {
    position: "absolute",
    right: 0,
  },
  row: {
    lineHeight: 4,
    cursor: "pointer",
    ":hover": {
      bg: "text",
      color: "background",
    },
  },
  td: {
    px: 4,
    borderBottom: "1px solid",
    borderColor: "border",
  },
};

function ColumnHeading({ label, showIcon, ...props }) {
  return (
    <Button sx={sx.button} {...props}>
      {label}
      <SortIcon sx={{ ...sx.sort, opacity: showIcon ? 1 : 0 }} width="1em" height="1em" />
    </Button>
  );
}

function TableHeader({ sortBy, onSort, ...props }) {
  return (
    <thead>
      <tr>
        <th sx={{ ...sx.th, px: 0 }}>
          <ColumnHeading
            label="Status"
            showIcon={sortBy === VIDEO_FIELDS.status}
            onClick={() => onSort(VIDEO_FIELDS.status)}
            sx={{ mx: "auto" }}
          />
        </th>
        <th sx={sx.th}>
          <ColumnHeading
            label="Video ID"
            showIcon={sortBy === VIDEO_FIELDS.alimentivVideoId}
            onClick={() => onSort(VIDEO_FIELDS.alimentivVideoId)}
          />
        </th>
        <th sx={sx.th}>
          <ColumnHeading
            label="Duration"
            showIcon={sortBy === VIDEO_FIELDS.duration}
            onClick={() => onSort(VIDEO_FIELDS.duration)}
          />
        </th>
        <th sx={sx.th}>
          <ColumnHeading
            label="Date completed"
            showIcon={sortBy === VIDEO_FIELDS.completedAt}
            onClick={() => onSort(VIDEO_FIELDS.completedAt)}
          />
        </th>
      </tr>
    </thead>
  );
}

function StatusIcon({ status }) {
  if (status === STATUS.LABELLED) {
    return (
      <LabelledIcon
        width="1em"
        height="1em"
        title="Complete"
        aria-label="Complete"
        sx={{ verticalAlign: "middle" }}
      />
    );
  }
  if (status === STATUS.INPROGRESS) {
    return (
      <InProgressIcon
        width="1em"
        height="1em"
        title="In progress"
        aria-label="In progress"
        sx={{ verticalAlign: "middle" }}
      />
    );
  }
  if (status === STATUS.UNLABELLED) {
    return (
      <UnlabelledIcon
        width="1em"
        height="1em"
        title="Not started yet"
        aria-label="Not started yet"
        sx={{ verticalAlign: "middle", opacity: 0 }}
      />
    );
  }
  return null;
}

function Row({ video, ...props }) {
  const { status, alimentivVideoId, duration, completedAt } = video;
  return (
    <tr sx={sx.row} {...props}>
      <td sx={{ ...sx.td, fontSize: "1.8rem", textAlign: "center" }}>
        <StatusIcon status={status} />
      </td>
      <td sx={sx.td}>{alimentivVideoId}</td>
      <td sx={sx.td}>{formatSeconds(duration)}</td>
      <td sx={sx.td}>{completedAt && formatDate(completedAt)}</td>
    </tr>
  );
}

function ListPage({ videoList, handleSignOut, handleVideoDetail, ...props }) {
  const [sort, setSort] = useState({
    by: VIDEO_FIELDS.status,
    ascending: true,
  });
  const [showHotkeysDialog, setShowHotkeysDialog] = useState(false);

  const videos = videoList.sort((a, b) => {
    if (a[sort.by] < b[sort.by]) {
      return sort.ascending ? -1 : 1;
    }
    if (a[sort.by] > b[sort.by]) {
      return sort.ascending ? 1 : -1;
    }
    return 0;
  });

  function handleSort(sortBy) {
    if (sort.by === sortBy) {
      setSort(current => {
        return { ...current, ascending: !current.ascending };
      });
    } else {
      setSort(current => {
        return { ...current, by: sortBy };
      });
    }
  }

  return (
    <div {...props}>
      <Grid sx={sx.header}>
        <Logo sx={sx.logo} />
        <h1 sx={sx.title}>{config.appName}</h1>
        <AccountMenu
          sx={sx.menu}
          onSignOut={() => handleSignOut()}
          showHotkeysDialog={showHotkeysDialog}
          setShowHotkeysDialog={setShowHotkeysDialog}
        />
      </Grid>
      <table sx={sx.table}>
        <TableHeader sortBy={sort.by} onSort={handleSort} />
        <tbody>
          {videos.map(video => (
            <Row
              key={video.alimentivVideoId}
              video={video}
              onClick={() => handleVideoDetail(video.id)}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default ListPage;
