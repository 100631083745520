import { SORT_OPTIONS } from "./constants";

const maxEndTime = labels => Math.max(...labels.map(label => label.end_time || 0), 0);

const minStartTime = labels => Math.min(...labels.map(label => label.start_time));

const cumulativeDuration = labels =>
  labels
    .map(label => (label.end_time || label.start_time) - label.start_time)
    .reduce((acc, duration) => acc + duration, 0);

const sortByClassName = ([a_class], [b_class]) => {
  return a_class.toLowerCase().localeCompare(b_class.toLowerCase());
};

const sortByMostRecent = ([, a_labels], [, b_labels]) => {
  return maxEndTime(b_labels) - maxEndTime(a_labels);
};

const sortByFirstApperance = ([, a_labels], [, b_labels]) => {
  return minStartTime(a_labels) - minStartTime(b_labels);
};

const sortByCumulativeDuration = ([, a_labels], [, b_labels]) => {
  return cumulativeDuration(b_labels) - cumulativeDuration(a_labels);
};

export const SORT_FUNCTION = {
  [SORT_OPTIONS.MOST_RECENT]: sortByMostRecent,
  [SORT_OPTIONS.ALPHABETICAL]: sortByClassName,
  [SORT_OPTIONS.FIRST_APPEARANCE]: sortByFirstApperance,
  [SORT_OPTIONS.PREVALENCE]: sortByCumulativeDuration,
};
