import axios from "axios";
import { identity } from "fp-ts/es6/function";

const customAxios = axios.create();

customAxios.interceptors.response.use(identity, error => {
  /* tslint:disable-next-line:no-if-statement */
  if (
    error.response &&
    error.response.status &&
    error.response.status.toString() &&
    error.response.status.toString().includes("40") &&
    window.location.pathname !== "/login"
  ) {
    /* tslint:disable-next-line:no-object-mutation */
    window.location.assign("/login");
  } else {
    return Promise.reject(error);
  }
});

export default customAxios;
