/** @jsx jsx */
import { jsx, Box, IconButton } from "theme-ui";
import { useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";

import PopupMenu from "./PopupMenu";
import HotkeysDialog from "./HotkeysDialog";

import { ReactComponent as MenuIcon } from "../img/menu.svg";

const sx = {
  container: {
    position: "relative",
  },
  toggle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    bg: "transparent",
    color: "text",
    fontSize: "2.4rem",
    fontWeight: "bold",
    cursor: "pointer",
    ":hover": {
      bg: "transparent",
      color: "inherit",
    },
  },
  caret: {
    ml: 1,
  },
  menu: {
    position: "absolute",
    top: "100%",
    right: 1,
    mt: 2,
  },
};

function AccountMenu({
  username = "Menu",
  onSignOut = () => {},
  showHotkeysDialog,
  setShowHotkeysDialog,
  ...props
}) {
  const [isOpen, setIsOpen] = useState(false);

  useHotkeys("shift+/", e => {
    e.stopPropagation();
    e.preventDefault();
    setShowHotkeysDialog(true);
  });

  const options = [
    {
      name: username,
    },
    {
      name: "Keyboard shortcuts",
      action: () => setShowHotkeysDialog(true),
    },
    {
      name: "Sign out",
      action: () => onSignOut(),
    },
  ];

  return (
    <Box sx={sx.container} {...props}>
      <IconButton
        sx={sx.toggle}
        title="Menu"
        aria-label="Menu"
        onClick={() => setIsOpen(prev => !prev)}
      >
        <MenuIcon width="1em" height="1em" />
      </IconButton>
      {isOpen && <PopupMenu sx={sx.menu} onClose={() => setIsOpen(false)} options={options} />}
      {showHotkeysDialog && <HotkeysDialog onClose={() => setShowHotkeysDialog(false)} />}
    </Box>
  );
}

export default AccountMenu;
