import { TypeOf, Type } from "io-ts";
import { AxiosResponse } from "axios";
import { decoderFor } from "./decoderFor";

export function decodeAxiosResponse<A = any, O = A, I = unknown>(c: Type<A, O, I>) {
  return (resp: AxiosResponse<any>): AxiosResponse<TypeOf<typeof c>> => ({
    ...resp,
    data: decoderFor(c)(resp.data),
  });
}
