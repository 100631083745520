/** @jsx jsx */
import { jsx } from "theme-ui";

import HoverTimeIndicator from "./HoverTimeIndicator";
import HoveredLabelTimeIndicator from "./HoveredLabelTimeIndicator";
import { spaceToTime, timeToSpace } from "../../utils";

import { ReactComponent as FurthestViewedIcon } from "../../img/arrow-to-right.svg";

const sx = {
  axis: {
    position: "relative",
    width: "100%",
    height: "3.2rem",
    bg: "background",
  },
  tick: {
    position: "absolute",
    bottom: 0,
    width: "1px",
    bg: "ticks",
  },
  minute: {
    height: "0.8rem",
  },
  tenSeconds: {
    height: "0.4rem",
  },
  second: {
    height: "0.2rem",
  },
  number: {
    position: "absolute",
    top: "0.8rem",
    width: "6rem",
    ml: "-3rem",
    fontFamily: "monospace",
    fontSize: 2,
    textAlign: "center",
    lineHeight: 1,
    color: "ticks",
    pointerEvents: "none",
  },
  furthestViewed: {
    position: "absolute",
    bottom: 0,
    ml: "-1.2rem",
    p: 0,
    width: "1.3rem",
    height: "1.3rem",
    fontSize: 1,
    color: "accent",
    cursor: "pointer",
    transition: "opacity 150ms",
    ":hover": {
      opacity: 1,
    },
  },
};

function Axis({
  duration,
  zoom,
  tracksRef,
  currentTime,
  furthestViewedTime,
  hideHoverIndicator,
  isScrubbingLabelStartWithClickDelta,
  isScrubbingLabelEndWithClickDelta,
  hoveredLabel,
  ...props
}) {
  if (!duration) {
    return null;
  }

  const minutes = Math.floor(duration / 60);
  const tenSeconds = Math.floor(duration / 10);

  return (
    <div sx={sx.axis} {...props}>
      {minutes > 0 &&
        [...Array(minutes)].map((_, i) => (
          <span
            key={i + 1}
            sx={{
              ...sx.number,
              left: `${zoom * (i + 1) * 60}px`,
            }}
          >{`${i + 1}m`}</span>
        ))}
      {minutes < 1 &&
        [...Array(tenSeconds)].map((_, i) => (
          <span
            key={i + 1}
            sx={{
              ...sx.number,
              left: `${zoom * (i + 1) * 10}px`,
            }}
          >{`${(i + 1) * 10}s`}</span>
        ))}
      {[...Array(tenSeconds)].map((_, i) => (
        <span
          key={i + 1}
          sx={{
            ...sx.tick,
            ...((i + 1) % 6 === 0 ? sx.minute : sx.tenSeconds),
            left: `${zoom * (i + 1) * 10}px`,
          }}
        />
      ))}
      <FurthestViewedIcon
        sx={{
          ...sx.furthestViewed,
          left: `${timeToSpace(furthestViewedTime, zoom)}px`,
          opacity: furthestViewedTime > currentTime + spaceToTime(20, zoom) ? 0.7 : 0,
        }}
        title="Furthest seen time"
        aria-label="Furthest seen time"
      />
      <HoverTimeIndicator
        zoom={zoom}
        tracksRef={tracksRef}
        show={!hideHoverIndicator && !hoveredLabel?.end_time}
      />
      {!hideHoverIndicator && hoveredLabel?.end_time && (
        <HoveredLabelTimeIndicator
          zoom={zoom}
          label={hoveredLabel}
          duration={duration}
          isScrubbingLabelStartWithClickDelta={isScrubbingLabelStartWithClickDelta}
          isScrubbingLabelEndWithClickDelta={isScrubbingLabelEndWithClickDelta}
          tracksRef={tracksRef}
        />
      )}
    </div>
  );
}

export default Axis;
