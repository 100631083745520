const theme = {
  space: [0, "0.8rem", "1.6rem", "2.4rem", "3.2rem", "4rem", "4.8rem", "5.6rem", "6.4rem"],
  fonts: {
    body:
      'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
    heading: "inherit",
    monospace: "'Roboto Mono', monospace",
  },
  fontSizes: [
    "1.1rem",
    "1.2rem",
    "1.3rem",
    "1.4rem",
    "1.6rem",
    "1.8rem",
    "2rem",
    "2.8rem",
    "3.4rem",
  ],
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  colors: {
    text: "#d6c8a4",
    background: "#111",
    backgroundGray: "#222",
    poorQualityBg: "#2b1b1b",
    border: "#d6c8a4",
    divider: "#403c32",
    controls: "#d6c8a4",
    ticks: "#887c5c",
    disabled: "#555",
    muted: "#111",
    accent: "#daa520",
    label: "#ada799",
    labelHover: "#e6c97f",
    labelActive: "#e6c97f",
    labelTentative: "#e6c97f",
    stop: "#ad4444",
    scrim: "#000000cc",
  },
  shadows: [
    "none",
    "0 1px 1px 0 rgba(0,0,0,0.64), 0 2px 1px -1px rgba(0,0,0,0.62), 0 1px 3px 0 rgba(0,0,0,0.70)",
    "0 4px 5px 0 rgba(0,0,0,0.64), 0 1px 10px 0 rgba(0,0,0,0.62), 0 2px 4px -1px rgba(0,0,0,0.70)",
    "0 8px 10px 1px rgba(0,0,0,0.64), 0 3px 14px 2px rgba(0,0,0,0.62), 0 5px 5px -3px rgba(0,0,0,0.70)",
    "0 16px 24px 2px rgba(0,0,0,0.64), 0 6px 30px 5px rgba(0,0,0,0.62), 0 8px 10px -5px rgba(0,0,0,0.70)",
  ],
  text: {
    heading: {
      color: "text",
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
    },
    code: {
      fontFamily: "monospace",
      bg: "tint",
      px: "0.4rem",
    },
  },
  buttons: {
    primary: {
      m: 0,
      px: 2,
      py: 1,
      font: "inherit",
      border: "2px solid transparent",
      borderRadius: "2px",
      bg: "text",
      color: "background",
      cursor: "pointer",
    },
    secondary: {
      variant: "buttons.primary",
      bg: "transparent",
      color: "text",
      borderWidth: "2px",
      borderStyle: "solid",
      borderColor: "border",
    },
    text: {
      variant: "buttons.primary",
      p: 0,
      bg: "transparent",
      color: "text",
      boxShadow: 0,
      ":hover": {
        textDecoration: "underline",
      },
      ":focus": {
        textDecoration: "underline",
      },
    },
  },
  styles: {
    root: {
      fontFamily: "body",
      lineHeight: "body",
      fontWeight: "body",
      fontSize: 4,
      position: "relative",
      width: "100%",
      m: 0,
      p: 0,
    },
    a: {
      textDecoration: "none",
      color: "accent",
    },
  },
};

export default theme;
