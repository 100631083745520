interface Config {
  appName: string;
  labelClassOrder: { [name: string]: number };
  viewportShort: string;
}

const config: Config = {
  appName: "ENDO",
  labelClassOrder: {
    "Stool, Solid": 1,
    "Stool, Liquid": 2,
    "Mucopurulent Exudate": 3,
    Mucous: 4,
    "Inadequate Insufflation": 5,
    "Not Readable": 6,
    Erosion: 7,
    "Ulcer, Large'": 8,
    "Ulcer, Very Larg": 9,
    Erythema: 10,
    "Erythema, Marked": 11,
    "Vascular Pattern, Absent": 12,
    "Vascular Pattern, Decreased": 13,
    Friability: 14,
    "Spontaneous Bleeding": 15,
    "Instrumentation-related bleeding": 16,
    "Endoscopic tool": 17,
  },
  viewportShort: "@media (max-height: 760px)",
};

export default config;
