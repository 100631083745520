/** @jsx jsx */
import { jsx } from "theme-ui";
import { useState, useRef } from "react";

import useEventListener from "../../hooks/useEventListener";

import { formatSeconds, spaceToTime, clickToPositionX } from "../../utils";

const sx = {
  indicator: {
    position: "absolute",
    bottom: 0,
    zIndex: 1,
    width: "1px",
    height: "70%",
    borderWidth: "0 0 0 1px",
    borderStyle: "dashed",
    borderColor: "accent",
    pointerEvents: "none",
    transition: "opacity 150ms",
    "::before": {
      content: "attr(data-time)",
      position: "absolute",
      bottom: "calc(100% + 0.8rem)",
      left: "-4rem",
      width: "8rem",
      textAlign: "center",
      fontSize: 1,
      lineHeight: 1,
      color: "accent",
      pointerEvents: "none",
    },
  },
};

function HoverTimeIndicator({ tracksRef, zoom, show, ...props }) {
  const [showIndicator, setShowIndicator] = useState(false);
  const indicatorRef = useRef();

  useEventListener("mouseenter", () => setShowIndicator(true), tracksRef.current);

  useEventListener(
    "mousemove",
    e => {
      requestAnimationFrame(() => {
        const position = clickToPositionX(e, tracksRef.current);
        const currentIdicator = indicatorRef.current;
        if (currentIdicator.style) {
          currentIdicator.style.setProperty("left", `${position}px`);
        }
        if (currentIdicator.setAttribute) {
          currentIdicator.setAttribute("data-time", formatSeconds(spaceToTime(position, zoom)));
        }
      });
    },
    tracksRef.current
  );

  useEventListener("mouseleave", () => setShowIndicator(false), tracksRef.current);

  return (
    <div
      ref={indicatorRef}
      sx={{
        ...sx.indicator,
        opacity: show && showIndicator ? 1 : 0,
      }}
      {...props}
    />
  );
}

export default HoverTimeIndicator;
