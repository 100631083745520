export const VIEWPORT_SHORT = "@media (max-height: 760px)";

export const STATUS = {
  INPROGRESS: "1_INPROGRESS",
  UNLABELLED: "2_UNLABELLED",
  LABELLED: "3_LABELLED",
};

export const VIDEO_FIELDS = {
  alimentivVideoId: "alimentivVideoId",
  uri: "uri",
  status: "status",
  duration: "duration",
  completedAt: "completedAt",
};

export const LABEL_FIELDS = {
  class: "class",
  start_time: "start_time",
  end_time: "end_time",
};

export const SORT_OPTIONS = {
  FIRST_APPEARANCE: "First appearance",
  MOST_RECENT: "Most recent",
  PREVALENCE: "Prevalence",
  ALPHABETICAL: "Alphabetical",
};
