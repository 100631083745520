import * as t from "io-ts";

export const labelCreateCodec = t.type({
  classId: t.string,
  startTime: t.number,
  endTime: t.number,
});

export const labelCodec = t.intersection([
  labelCreateCodec,
  t.type({
    id: t.string,
    videoId: t.string,
    createdAt: t.string,
    modifiedAt: t.string,
  }),
]);

export type LabelCreate = t.TypeOf<typeof labelCreateCodec>;

export type Label = t.TypeOf<typeof labelCodec>;

export interface LabelPrime {
  id?: string;
  class: string;
  end_time: number;
  start_time: number;
}
