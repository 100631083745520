import { pipe } from "fp-ts/es6/function";
import { map, Option } from "fp-ts/es6/Option";
import { findFirst } from "fp-ts/es6/Array";

import { Label, LabelPrime } from "../datamodel/label";
import { LabelClass } from "../datamodel/labelClass";

const transformLabelToLabelPrime = (label: Label, labelClasses: LabelClass[]): Option<LabelPrime> =>
  pipe(
    findFirst((lc: LabelClass) => lc.id === label.classId)(labelClasses),
    map(
      lc =>
        ({
          id: label.id,
          class: lc.name,
          end_time: label.endTime / 1000,
          start_time: label.startTime / 1000,
        } as LabelPrime)
    )
  );

export default transformLabelToLabelPrime;
