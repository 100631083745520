import { fold } from "fp-ts/es6/Either";
import { pipe } from "fp-ts/es6/pipeable";
import { TypeOf, Type } from "io-ts";
import { PathReporter } from "io-ts/lib/PathReporter";

export function decoderFor<A = any, O = A, I = unknown>(c: Type<A, O, I>) {
  return (data: any): TypeOf<typeof c> => {
    const result = c.decode(data);
    return pipe(
      result,
      fold(
        () => {
          const e = PathReporter.report(result)
            .join("\n\n\n")
            .concat(` when decoding ${JSON.stringify(data)}`);
          throw new Error(e);
        },
        decoded => decoded
      )
    );
  };
}
