/** @jsx jsx */
import { jsx, Flex, Button } from "theme-ui";

const sx = {
  menu: {
    flexDirection: "column",
    alignItems: "stretch",
    pb: 1,
    bg: "background",
    borderWidth: "2px",
    borderStyle: "solid",
    borderColor: "border",
    minWidth: "16rem",
    maxHeight: "21.6rem",
    overflowY: "auto",
    scrollBehavior: "smooth",
    WebkitOverflowScrolling: "touch",
  },
  label: {
    mb: 1,
    py: "1.2rem",
    px: 2,
    textAlign: "left",
    color: "background",
    bg: "text",
    fontSize: 3,
    fontWeight: "bold",
    whiteSpace: "nowrap",
  },
  action: {
    py: 1,
    px: 2,
    mx: 1,
    textAlign: "left",
    color: "text",
    fontSize: 3,
    whiteSpace: "nowrap",
    "&:focus": {
      outlineOffset: "-2px",
    },
    "&:hover": {
      bg: "text",
      color: "background",
      textDecoration: "none",
    },
  },
};

function PopupMenu({ options = [], onClose = () => {}, ...props }) {
  return (
    <Flex sx={sx.menu} {...props}>
      {options.map(({ name, action }, i) => {
        return action ? (
          <Button
            key={i}
            variant="text"
            sx={sx.action}
            onClick={() => {
              onClose();
              action();
            }}
          >
            {name}
          </Button>
        ) : (
          <div key={i} sx={sx.label}>
            {name}
          </div>
        );
      })}
    </Flex>
  );
}

export default PopupMenu;
