/** @jsx jsx */
import { jsx, Box, Flex, Heading, IconButton } from "theme-ui";
import { useRef, useEffect } from "react";
import usePortal from "react-useportal";
import { useHotkeys } from "react-hotkeys-hook";

import { ReactComponent as BackIcon } from "../img/back.svg";
import { ReactComponent as CloseIcon } from "../img/close.svg";

const sx = {
  scrim: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    pt: "16vh",
    alignItems: "center",
    bg: "scrim",
    zIndex: 20,
  },
  dialog: {
    flexDirection: "column",
    width: "32rem",
    minWidth: "34rem",
    maxWidth: "64rem",
    mx: 8,
    px: 2,
    pb: 2,
    border: "2px solid",
    bg: "background",
    overflowY: "hidden",
    boxShadow: 3,
  },
  header: {
    flex: "none",
    flexDirection: "row",
    alignItems: "center",
    px: 3,
    py: 3,
  },
  back: {
    flex: "none",
    mr: 1,
    fontSize: "1.6rem",
  },
  title: {
    flex: "auto",
    fontSize: 5,
    fontWeight: "bold",
  },
  cancel: {
    flex: "none",
    ml: "auto",
    mr: -1,
    fontSize: 5,
    cursor: "pointer",
  },
  contents: {
    flex: "auto",
    px: 3,
    pb: 3,
    overflowY: "auto",
  },
};

function Dialog({ title, children, onCancel, onBack, closeOnScrimClick = true, ...props }) {
  const { Portal } = usePortal();

  const scrimRef = useRef();
  const dialogRef = useRef();

  useEffect(() => {
    const trapFocus = e => {
      const dialogEl = dialogRef.current;
      if (!dialogEl.contains(e.target)) {
        const newTarget =
          dialogEl.querySelectorAll("input,select,textarea")[0] ||
          dialogEl.querySelectorAll("button")[0];
        if (newTarget) newTarget.focus();
      }
    };
    document.addEventListener("focus", trapFocus, true);
    return () => document.removeEventListener("focus", trapFocus, true);
  }, []);

  useHotkeys("esc", e => {
    e.stopPropagation();
    e.preventDefault();
    if (onCancel) onCancel();
  });

  const handleScrimClick = e => {
    if (closeOnScrimClick && e.target === scrimRef.current) {
      if (onCancel) onCancel();
    }
  };

  return (
    <Portal>
      <Box sx={sx.scrim} ref={scrimRef} onClick={handleScrimClick}>
        <Flex sx={sx.dialog} ref={dialogRef} {...props}>
          <Flex as="header" sx={sx.header}>
            {onBack && (
              <IconButton sx={sx.back} onClick={onBack}>
                <BackIcon width="1em" height="1em" />
              </IconButton>
            )}
            <Heading as="h3" sx={sx.title}>
              {title}
            </Heading>
            {onCancel && (
              <IconButton sx={sx.cancel} onClick={onCancel}>
                <CloseIcon width="1em" height="1em" />
              </IconButton>
            )}
          </Flex>
          <Box sx={sx.contents}>{children}</Box>
        </Flex>
      </Box>
    </Portal>
  );
}

export default Dialog;
