/** @jsx jsx */
import config from "../../config";
import { useState } from "react";
import { jsx, Box, Flex, Heading, Card, IconButton, Label, Input, Button } from "theme-ui";
import { ReactComponent as BackIcon } from "../../img/back.svg";
import { ReactComponent as Logo } from "../../img/logo.svg";

const DEV_LOGIN = "http://localhost:5000/";
const STG_LOGIN = "https://sso.tst-robartsinc.com/";

const sx = {
  page: {
    height: "100%",
    maxHeight: "100%",
    flexDirection: "column",
    alignItems: "center",
    p: 6,
    pt: "12rem",
    overflow: "auto",
    WebkitOverflowScrolling: "touch",
  },
  header: {
    flexDirection: "column",
    alignItems: "center",
    mb: 8,
  },
  logo: {
    mb: 3,
  },
  heading: {
    flex: "none",
    fontWeight: 200,
    fontSize: "4.8rem",
  },
  card: {
    flex: "none",
    borderWidth: "2px",
    borderStyle: "solid",
    borderColor: "border",
    width: "40rem",
  },
  cardHeader: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    height: "6.4rem",
    px: "4.8rem",
  },
  cardHeading: {
    m: 0,
    fontSize: 5,
    lineHeight: 1,
  },
  cardBack: {
    position: "absolute",
    top: "0.8rem",
    left: "0.8rem",
    width: "4.8rem",
    height: "4.8rem",
    fontSize: "2rem",
    cursor: "pointer",
  },
  cardBody: {
    pt: 4,
    pb: 5,
    px: 5,
  },
  label: {
    mb: 1,
    fontSize: 5,
    fontWeight: "bold",
  },
  input: {
    mb: 4,
  },
  submit: {
    width: "100%",
    mt: 2,
    py: 2,
    fontSize: 5,
    fontWeight: "bold",
    lineHeight: 1,
  },
  modeButtons: {
    mt: 6,
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    width: "40rem",
  },
  modeButton: {
    flex: "none",
    color: "text",
  },
};

function CardHeader({ title, onBack }) {
  return (
    <Flex as="header" sx={sx.cardHeader}>
      <IconButton onClick={onBack} sx={sx.cardBack} aria-label="Back">
        <BackIcon width="1em" height="1em" />
      </IconButton>
      <Heading as="h2" sx={sx.cardHeading}>
        {title}
      </Heading>
    </Flex>
  );
}

function SignInCard() {
  const onClick = () => {
    const hostName = new URL(window.location.href).hostname;
    if (hostName.includes("localhost")) {
      window.location.assign(DEV_LOGIN);
    } else {
      window.location.assign(STG_LOGIN);
    }
  };

  return (
    <Card sx={sx.card}>
      <Box sx={sx.cardBody}>
        <Box>
          {/* <Label htmlFor="email" sx={sx.label}>
            Email
          </Label>
          <Input id="email" sx={sx.input} autoFocus />
          <Label htmlFor="password" sx={sx.label}>
            Password
          </Label>
          <Input id="password" type="password" sx={sx.input} /> */}
          <Button variant="primary" sx={sx.submit} onClick={onClick}>
            Sign in
          </Button>
        </Box>
      </Box>
    </Card>
  );
}

function ResetPasswordCard({ onBack }) {
  return (
    <Card sx={sx.card}>
      <CardHeader onBack={onBack} title="Reset password" />
      <Box sx={sx.cardBody}>
        <Box
          as="form"
          onSubmit={e => {
            e.preventDefault();
          }}
        >
          <Label htmlFor="email" sx={sx.label}>
            Email
          </Label>
          <Input id="email" sx={sx.input} autoFocus />
          <Button variant="primary" sx={sx.submit} type="submit">
            Reset password
          </Button>
        </Box>
      </Box>
    </Card>
  );
}

function CreateAccountCard({ onBack }) {
  return (
    <Card sx={sx.card}>
      <CardHeader onBack={onBack} title="Create account" />
      <Box sx={sx.cardBody}>
        <Box
          as="form"
          onSubmit={e => {
            e.preventDefault();
          }}
        >
          <Label htmlFor="name" sx={sx.label}>
            Name
          </Label>
          <Input id="name" sx={sx.input} autoFocus />
          <Label htmlFor="email" sx={sx.label}>
            Email
          </Label>
          <Input id="email" sx={sx.input} />
          <Label htmlFor="password" sx={sx.label}>
            Password
          </Label>
          <Input id="password" type="password" sx={sx.input} />
          <Button variant="primary" sx={sx.submit} type="submit">
            Create account
          </Button>
        </Box>
      </Box>
    </Card>
  );
}

function AuthPage() {
  const [mode, setMode] = useState("signin");

  let card;
  if (mode === "create") {
    card = <CreateAccountCard onBack={() => setMode("signin")} />;
  } else if (mode === "reset") {
    card = <ResetPasswordCard onBack={() => setMode("signin")} />;
  } else {
    card = <SignInCard />;
  }

  return (
    <Flex sx={sx.page}>
      <Flex sx={sx.header} as="header">
        <Logo sx={sx.logo} />
        <Heading as="h1" sx={sx.heading}>
          {config.appName}
        </Heading>
      </Flex>
      {card}
      {/* {mode === "signin" && (
        <Flex sx={sx.modeButtons}>
          <Button variant="buttons.text" sx={sx.modeButton} onClick={() => setMode("reset")}>
            Reset password
          </Button>
          <Button variant="buttons.text" sx={sx.modeButton} onClick={() => setMode("create")}>
            Need an account?
          </Button>
        </Flex>
      )} */}
    </Flex>
  );
}

export default AuthPage;
