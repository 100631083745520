import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";

import ListPage from "./ListPage";
import { VideoPrime } from "../../datamodel/video";
import { listVideos } from "../../http/video";
import FullScreenLoader from "../../components/FullScreenLoader";
import transformDomain from "../../lib/transformDomain";
import transformVideoToPrime from "../../lib/transformVideoToPrime";

const VideoList = () => {
  const [videos, setVideos] = useState<VideoPrime[]>([]);
  const [fetchSuccess, setFetchSuccess] = useState<boolean>(false);

  const history = useHistory();

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const { data: videoList } = await listVideos();
        const transformedVideos = videoList.map(transformVideoToPrime);
        setVideos(transformedVideos);
        setFetchSuccess(true);
      } catch (err) {
        console.error(err);
        setFetchSuccess(false);
      }
    };
    fetchVideos();
  }, []);

  const handleSignOut = (): void => {
    const url = new URL(window.location.href);
    Cookies.remove("sso-token", {
      domain: transformDomain(url),
      path: "/",
    });
    history.push("/login");
  };

  const handleVideoDetail = (id: string): void => {
    history.push(`/videos/${id}/label`);
  };

  return fetchSuccess ? (
    <ListPage
      videoList={videos}
      handleVideoDetail={handleVideoDetail}
      handleSignOut={handleSignOut}
    />
  ) : (
    <FullScreenLoader />
  );
};

export default VideoList;
