/** @jsx jsx */
import { jsx, Flex, IconButton } from "theme-ui";
import { useRef, useEffect } from "react";

import Slider from "./Slider";
import { formatSeconds } from "../../utils";
import config from "../../config";

import { ReactComponent as FullscreenIcon } from "../../img/fullscreen.svg";
import { ReactComponent as PlayIcon } from "../../img/play.svg";
import { ReactComponent as PauseIcon } from "../../img/pause.svg";
import { ReactComponent as UndoIcon } from "../../img/undo.svg";
import { ReactComponent as JumpBackIcon } from "../../img/jump-back.svg";
import { ReactComponent as JumpForwardIcon } from "../../img/jump-forward.svg";

const VIEWPORT_SHORT = config.viewportShort;

const sx = {
  controls: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "stretch",
    pr: 1,
    "--loaded": "0%",
    "--played": "0%",
    "--playbackRateFillPct": "50%",
    "--playbackRateDeg": "90deg",
    "--playbackRateBackground": "#333",
  },
  time: {
    flexDirection: "row",
    alignItems: "center",
    mb: 2,
    fontFamily: "monospace",
    fontSize: 3,
    [VIEWPORT_SHORT]: {
      mb: 1,
    },
  },
  played: {
    flex: "none",
    width: "6ch",
    textAlign: "right",
  },
  duration: {
    flex: "none",
    width: "7ch",
    textAlign: "left",
  },
  scrubber: {
    flex: "auto",
    mx: 2,
  },
  fullscreen: {
    flex: "none",
    ml: 2,
    color: "controls",
    opacity: 0.6,
    cursor: "pointer",
    ":focus": {
      outline: "none",
      bg: "#333",
    },
  },
  playback: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    mt: 2,
    [VIEWPORT_SHORT]: {
      justifyContent: "flex-start",
      mt: 0,
    },
  },
  play: {
    flex: "none",
    bg: "backgroundGray",
    ml: 2,
    p: 2,
    color: "controls",
    width: "7.2rem",
    height: "7.2rem",
    borderRadius: "50px",
    cursor: "pointer",
    ":focus": {
      outline: "none",
      bg: "#333",
    },
    [VIEWPORT_SHORT]: {
      width: "5.6rem",
      height: "5.6rem",
      mr: 4,
      p: "1.2rem",
    },
  },
  jump: {
    flex: "none",
    flexDirection: "row",
    alignItems: "center",
    [VIEWPORT_SHORT]: {
      mr: 4,
    },
  },
  jumpButton: {
    appearance: "none",
    flex: "none",
    bg: "transparent",
    mx: "0.4rem",
    p: 0,
    border: "none",
    fill: "controls",
    width: "4.8rem",
    height: "4.8rem",
    cursor: "pointer",
    ":focus": {
      outline: "none",
      bg: "#333",
    },
    [VIEWPORT_SHORT]: {
      width: "4rem",
      height: "4rem",
    },
  },
  rate: {
    flex: "none",
    mr: 1,
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  rateControl: {
    flex: "none",
    width: "3.2rem",
    position: "relative",
    display: "grid",
    placeItems: "center",
  },
  rateLabel: {
    width: "100%",
    fontFamily: "monospace",
    fontSize: 3,
    textAlign: "right",
  },
  rateReset: {
    opacity: 0,
    pointerEvents: "none",
    position: "absolute",
    right: 0,
    width: "3rem",
    height: "3rem",
    cursor: "pointer",
  },
  rateSlider: {
    flex: "none",
    width: "8rem",
    mr: 1,
  },
};

const SCRUBBER_BG = `linear-gradient(90deg, var(--trackPlayedColor) var(--played), transparent var(--played)),
                     linear-gradient(90deg, var(--trackLoadedColor) var(--loaded), transparent var(--loaded))`;

const RATE_BG = `linear-gradient(var(--playbackRateDeg), 
                                 var(--playbackRateBackground) var(--playbackRateFillPct), 
                                 var(--playbackRateColor) var(--playbackRateFillPct), 
                                 var(--playbackRateColor) 50%,
                                 var(--playbackRateBackground) 50%)`;

function PlaybackControls({
  duration,
  loaded,
  played,
  playedSeconds,
  isPlaying,
  onPlay,
  onScrubbing,
  onScrub,
  playbackRate,
  onPlaybackRateChange,
  onFullscreen,
  ...props
}) {
  const controlsRef = useRef();

  function setCssProperty(property, value) {
    controlsRef.current.style.setProperty(property, value);
  }

  useEffect(() => {
    setCssProperty("--loaded", `${loaded * 100}%`);
  }, [loaded]);

  useEffect(() => {
    setCssProperty("--played", `${played * 100}%`);
  }, [played]);

  useEffect(() => {
    controlsRef.current.style.setProperty(
      "--playbackRateFillPct",
      `${playbackRate < 1 ? 50 * playbackRate : 50 * (2 - playbackRate)}%`
    );
    controlsRef.current.style.setProperty(
      "--playbackRateDeg",
      playbackRate < 1 ? "90deg" : "270deg"
    );
  }, [playbackRate]);

  const PrimaryIcon = isPlaying ? PauseIcon : PlayIcon;
  const primaryButtonTitle = isPlaying ? "Pause (space)" : "Play (space)";

  return (
    <Flex sx={sx.controls} ref={controlsRef} {...props}>
      <Flex sx={sx.time}>
        <span sx={sx.played}>{formatSeconds(playedSeconds)}</span>
        <Slider
          sx={sx.scrubber}
          backgroundImage={SCRUBBER_BG}
          min={0}
          max={duration}
          value={playedSeconds}
          onMouseDown={() => onScrubbing(true)}
          onChange={e => onScrub(e.target.value)}
          onMouseUp={() => onScrubbing(false)}
        />
        <span sx={sx.duration}>&minus;{formatSeconds(duration - playedSeconds)}</span>
        <IconButton
          sx={sx.fullscreen}
          aria-label="Fullscreen"
          title="Fullscreen"
          onClick={onFullscreen}
        >
          <FullscreenIcon width={24} height={24} />
        </IconButton>
      </Flex>
      <Flex sx={sx.playback}>
        <IconButton
          sx={sx.play}
          aria-label={primaryButtonTitle}
          title={primaryButtonTitle}
          onClick={() => onPlay(!isPlaying)}
        >
          <PrimaryIcon width="80%" height="80%" />
        </IconButton>
        <Flex sx={sx.jump}>
          <IconButton
            sx={sx.jumpButton}
            aria-label="Jump back 5 seconds (shift + left arrow)"
            title="Jump back 5 seconds (shift + &larr;)"
            onClick={() => onScrub(Math.max(0, playedSeconds - 5))}
          >
            <JumpBackIcon width="100%" height="100%" />
          </IconButton>
          <IconButton
            sx={sx.jumpButton}
            aria-label="Jump forward 5 seconds (shift + right arrow)"
            title="Jump forward 5 seconds (shift + &rarr;)"
            onClick={() => onScrub(Math.min(duration, playedSeconds + 5))}
          >
            <JumpForwardIcon width="100%" height="100%" />
          </IconButton>
        </Flex>
        <Flex sx={sx.rate}>
          <Slider
            sx={sx.rateSlider}
            backgroundImage={RATE_BG}
            title="Adjust playback rate"
            min={0.1}
            max={2}
            step={0.1}
            value={playbackRate}
            onChange={e => onPlaybackRateChange(parseFloat(e.target.value))}
          />
          <div
            sx={{
              ...sx.rateControl,
              ...(playbackRate !== 1
                ? {
                    ":hover span": {
                      opacity: 0,
                      pointerEvents: "none",
                    },
                    ":hover button": {
                      opacity: 1,
                      pointerEvents: "auto",
                    },
                  }
                : {}),
            }}
          >
            <span sx={sx.rateLabel}>{playbackRate}x</span>
            <IconButton onClick={() => onPlaybackRateChange(1)} sx={sx.rateReset}>
              <UndoIcon width="100%" height="100%" />
            </IconButton>
          </div>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default PlaybackControls;
