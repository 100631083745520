/** @jsx jsx */
import { jsx } from "theme-ui";

const sx = {
  loader: {
    position: "fixed",
    zIndex: 99999,
    height: "2em",
    width: "2em",
    overflow: "show",
    margin: "auto",
    top: "0",
    left: "0",
    bottom: "0",
    right: "0",
    "&:before": {
      content: '""',
      display: "block",
      position: "fixed",
      top: "0",
      left: "0",
      width: "100%",
      height: "100%",
      background: [
        "radial-gradient(rgba(20, 20, 20, 0.8), rgba(0, 0, 0, 0.8))",
        "-webkit-radial-gradient(rgba(20, 20, 20, 0.8), rgba(0, 0, 0, 0.8))",
      ],
    },
    "&:not(:required)": {
      font: "0/0 a",
      color: "transparent",
      textShadow: "none",
      backgroundColor: "transparent",
      border: "0",
    },
    "&:not(:required):after": {
      content: '""',
      display: "block",
      fontSize: "10px",
      width: "1em",
      height: "1em",
      marginTop: "-0.5em",
      WebkitAnimation: "spinner 150ms infinite linear",
      MozAnimation: "spinner 150ms infinite linear",
      msAnimation: "spinner 150ms infinite linear",
      OAnimation: "spinner 150ms infinite linear",
      animation: "spinner 150ms infinite linear",
      borderRadius: "0.5em",
      WebkitBoxShadow:
        "rgba(255, 255, 255, 0.75) 1.5em 0 0 0,\n      rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0, rgba(255, 255, 255, 0.75) 0 1.5em 0 0,\n      rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0, rgba(255, 255, 255, 0.75) -1.5em 0 0 0,\n      rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0, rgba(255, 255, 255, 0.75) 0 -1.5em 0 0,\n      rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0",
      boxShadow:
        "rgba(255, 255, 255, 0.75) 1.5em 0 0 0, rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0,\n      rgba(255, 255, 255, 0.75) 0 1.5em 0 0, rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0,\n      rgba(255, 255, 255, 0.75) -1.5em 0 0 0, rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0,\n      rgba(255, 255, 255, 0.75) 0 -1.5em 0 0, rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0",
    },
    "@-webkit-keyframes spinner": {
      "0%": {
        WebkitTransform: "rotate(0deg)",
        MozTransform: "rotate(0deg)",
        msTransform: "rotate(0deg)",
        OTransform: "rotate(0deg)",
        transform: "rotate(0deg)",
      },
      "100%": {
        WebkitTransform: "rotate(360deg)",
        MozTransform: "rotate(360deg)",
        msTransform: "rotate(360deg)",
        OTransform: "rotate(360deg)",
        transform: "rotate(360deg)",
      },
    },
    "@-moz-keyframes spinner": {
      "0%": {
        WebkitTransform: "rotate(0deg)",
        MozTransform: "rotate(0deg)",
        msTransform: "rotate(0deg)",
        OTransform: "rotate(0deg)",
        transform: "rotate(0deg)",
      },
      "100%": {
        WebkitTransform: "rotate(360deg)",
        MozTransform: "rotate(360deg)",
        msTransform: "rotate(360deg)",
        OTransform: "rotate(360deg)",
        transform: "rotate(360deg)",
      },
    },
    "@-o-keyframes spinner": {
      "0%": {
        WebkitTransform: "rotate(0deg)",
        MozTransform: "rotate(0deg)",
        msTransform: "rotate(0deg)",
        OTransform: "rotate(0deg)",
        transform: "rotate(0deg)",
      },
      "100%": {
        WebkitTransform: "rotate(360deg)",
        MozTransform: "rotate(360deg)",
        msTransform: "rotate(360deg)",
        OTransform: "rotate(360deg)",
        transform: "rotate(360deg)",
      },
    },
    "@keyframes spinner": {
      "0%": {
        WebkitTransform: "rotate(0deg)",
        MozTransform: "rotate(0deg)",
        msTransform: "rotate(0deg)",
        OTransform: "rotate(0deg)",
        transform: "rotate(0deg)",
      },
      "100%": {
        WebkitTransform: "rotate(360deg)",
        MozTransform: "rotate(360deg)",
        msTransform: "rotate(360deg)",
        OTransform: "rotate(360deg)",
        transform: "rotate(360deg)",
      },
    },
  },
};

export default () => <div sx={sx.loader}></div>;
