import { fromNullable } from "fp-ts/es6/Option";
import { STATUS } from "../constants";
import { Video, VideoPrime, VideoStatus } from "../datamodel/video";

const transformVideoPrimeToVideo = (video: VideoPrime): Video => ({
  ...video,
  userId: fromNullable(video.userId),
  status:
    video.status === STATUS.INPROGRESS
      ? VideoStatus.InProgress
      : video.status === STATUS.LABELLED
      ? VideoStatus.Labelled
      : VideoStatus.UnLabelled,
  completedAt: fromNullable(video.completedAt),
});

export default transformVideoPrimeToVideo;
