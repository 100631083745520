/** @jsx jsx */
import { jsx, Grid, IconButton, Button, Flex } from "theme-ui";
import { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player/file";
import { useHotkeys } from "react-hotkeys-hook";

import AccountMenu from "../../components/AccountMenu";
import PlaybackControls from "../../components/PlaybackControls";
import Timeline from "../../components/Timeline";
import ConfirmCompleteDialog from "../../components/ConfirmCompleteDialog";

import { formatSeconds, formatDate } from "../../utils";
import transformLabelPrimeToLabelCreate from "../../lib/transformLabelPrimeToLabelCreate";
import { STATUS } from "../../constants";
import sx from "./styles";
import { VideoContextProvider } from "../../components/data/VideoContext";

import { ReactComponent as BackIcon } from "../../img/back.svg";

function Fact({ name, value, ...props }) {
  return (
    <div sx={sx.fact} {...props}>
      <div sx={sx.factName}>{name}</div>
      <div sx={sx.factValue}>{value}</div>
    </div>
  );
}

function DetailPage({
  video,
  labelClasses,
  labelClassMap,
  labelClassCategory,
  poorQualityClasses,
  existingLabels,
  handleCreateLabel,
  handleUpdateLabel,
  handleDeleteLabel,
  handleVideoUpdate,
  handleVideoList,
  handleSignOut,
  ...props
}) {
  const [labels, setLabels] = useState(existingLabels);
  const [isPlaying, setIsPlaying] = useState(false);
  const [played, setPlayed] = useState(0);
  const [playedSeconds, setPlayedSeconds] = useState(0);
  const [loaded, setLoaded] = useState(0);
  const [duration, setDuration] = useState();
  const [playbackRate, setPlaybackRate] = useState(1);
  const [isScrubbing, setIsScrubbing] = useState();
  const [furthestViewedTime, setFurthestViewedTime] = useState(0);
  const [showConfirmCompleteDialog, setShowConfirmCompleteDialog] = useState(false);
  const [showHotkeysDialog, setShowHotkeysDialog] = useState(false);

  const playerRef = useRef();

  const [tentativeLabels, setTentativeLabels] = useState([]);
  const hasTentativeLabels = tentativeLabels && tentativeLabels.length > 0;
  const latestTentativeStartTime = hasTentativeLabels
    ? Math.max(...tentativeLabels.map(label => label.start_time))
    : null;

  useEffect(() => {
    setLabels(existingLabels);
  }, [existingLabels]);

  useEffect(() => {
    if (isPlaying && video.status !== STATUS.INPROGRESS) {
      handleVideoUpdate({
        ...video,
        status: STATUS.INPROGRESS,
      });
    }
  }, [video, isPlaying, handleVideoUpdate]);

  const handleTentativeLabelStart = labelClass => {
    setTentativeLabels(current => [
      ...current,
      {
        class: labelClass,
        start_time: playedSeconds,
      },
    ]);
  };

  const handleTentativeLabelEnd = label => {
    setTentativeLabels(current => current.filter(item => item !== label));
    label.end_time = label.tentative_end_time;
    label.tentative_end_time = null;

    if (label.start_time < label.end_time) {
      //post label
      handleCreateLabel(transformLabelPrimeToLabelCreate(label, labelClasses));
    }
  };

  const handleLabelChangeEnd = label => {
    label.end_time = playedSeconds;
    // update label
    handleUpdateLabel(label.id, transformLabelPrimeToLabelCreate(label, labelClasses));
  };

  function handleProgress({ playedSeconds, played, loaded }) {
    setLoaded(loaded);
    if (!isScrubbing) {
      setPlayed(played);
      setPlayedSeconds(playedSeconds);
      if (playedSeconds > furthestViewedTime) {
        setFurthestViewedTime(playedSeconds);
      }
    }
  }

  function handleScrub(time) {
    let seconds = parseFloat(time);

    if (latestTentativeStartTime && latestTentativeStartTime >= seconds) {
      seconds = latestTentativeStartTime + 1;
    }

    if (seconds < 0) {
      seconds = 0;
    } else if (seconds > duration) {
      seconds = duration;
    }

    const percent = parseFloat(seconds / duration);
    setPlayed(percent);
    setPlayedSeconds(seconds);
    seekTo(seconds);
  }

  function seekTo(amount, type = "seconds") {
    playerRef.current.seekTo(amount, type);
  }

  function handleEnded() {
    setIsPlaying(false);
  }

  function handleFullsceeen() {
    playerRef.current.getInternalPlayer().requestFullscreen();
  }

  function handleLabelClassChange(label, newClass) {
    // Disallow if newClass already as an overlapping label
    if (
      labels
        .filter(existingLabel => existingLabel.class === newClass)
        .some(
          existingLabel =>
            (existingLabel.start_time <= label.start_time &&
              existingLabel.end_time >= label.start_time) ||
            (existingLabel.start_time <= label.end_time && existingLabel.end_time >= label.end_time)
        )
    ) {
      return;
    }

    label.class = newClass;
    // update label
    handleUpdateLabel(label.id, transformLabelPrimeToLabelCreate(label, labelClasses));
  }

  function handleLabelDelete(label) {
    // delete label
    handleDeleteLabel(label.id);
  }

  function handleLabelStartTimeChange(label, time) {
    label.start_time = time;
    // update label
    handleUpdateLabel(label.id, transformLabelPrimeToLabelCreate(label, labelClasses));
  }

  function handleLabelEndTimeChange(label, time) {
    label.end_time = time;
    // update label
    handleUpdateLabel(label.id, transformLabelPrimeToLabelCreate(label, labelClasses));
  }

  const onClickComplete = () => {
    handleVideoUpdate(
      {
        ...video,
        status: STATUS.LABELLED,
      },
      false
    )
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        handleVideoList();
      });
  };

  const onClickReopen = () => {
    handleVideoUpdate({
      ...video,
      status: STATUS.INPROGRESS,
    });
  };

  const onClickFinishLater = () => {
    handleVideoUpdate(
      {
        ...video,
        status: STATUS.INPROGRESS,
      },
      false
    ).finally(() => {
      handleVideoList();
    });
  };

  useHotkeys("space", e => {
    e.stopPropagation();
    e.preventDefault();
    setIsPlaying(current => !current);
  });

  useHotkeys(
    "right",
    e => {
      e.stopPropagation();
      e.preventDefault();
      handleScrub(playedSeconds + 1);
    },
    [playedSeconds]
  );
  useHotkeys(
    "shift+right",
    e => {
      e.stopPropagation();
      e.preventDefault();
      handleScrub(playedSeconds + 5);
    },
    [playedSeconds]
  );
  useHotkeys(
    "left",
    e => {
      e.stopPropagation();
      e.preventDefault();
      handleScrub(playedSeconds - 1);
    },
    [playedSeconds]
  );
  useHotkeys(
    "shift+left",
    e => {
      e.stopPropagation();
      e.preventDefault();
      handleScrub(playedSeconds - 5);
    },
    [playedSeconds]
  );

  return (
    <Grid sx={sx.page} {...props}>
      <IconButton
        sx={sx.back}
        onClick={() => handleVideoList()}
        title="Back to list"
        aria-label="Back to list"
      >
        <BackIcon width="1em" height="1em" />
      </IconButton>
      <AccountMenu
        sx={sx.menu}
        onSignOut={() => handleSignOut()}
        showHotkeysDialog={showHotkeysDialog}
        setShowHotkeysDialog={setShowHotkeysDialog}
      />
      <Flex sx={sx.meta}>
        <Fact sx={sx.fact} name="Video ID" value={video.alimentivVideoId} />
        <Fact sx={sx.fact} name="Duration" value={formatSeconds(video.duration || duration)} />
      </Flex>
      {video.status !== STATUS.LABELLED && (
        <Flex sx={sx.complete}>
          <Button sx={sx.button} variant="secondary" onClick={onClickFinishLater}>
            Finish later
          </Button>
          <Button
            sx={sx.button}
            variant="primary"
            onClick={() => setShowConfirmCompleteDialog(true)}
          >
            Mark as complete
          </Button>
        </Flex>
      )}
      {video.status === STATUS.LABELLED && (
        <Flex sx={sx.complete}>
          <Fact sx={sx.fact} name="Completed" value={formatDate(video.completedAt)} />
          <Button sx={sx.button} variant="secondary" onClick={onClickReopen}>
            Re-open
          </Button>
        </Flex>
      )}
      <ReactPlayer
        ref={playerRef}
        sx={sx.player}
        url={video.uri}
        width={640}
        height={480}
        playing={isPlaying}
        playbackRate={playbackRate}
        progressInterval={1000 / playbackRate}
        onDuration={setDuration}
        onProgress={handleProgress}
        onEnded={handleEnded}
      />
      <PlaybackControls
        sx={sx.playback}
        duration={duration}
        loaded={loaded}
        played={played}
        playedSeconds={playedSeconds}
        isPlaying={isPlaying}
        onPlay={setIsPlaying}
        onScrubbing={setIsScrubbing}
        onScrub={handleScrub}
        playbackRate={playbackRate}
        onPlaybackRateChange={setPlaybackRate}
        onFullscreen={handleFullsceeen}
      />
      <VideoContextProvider value={isPlaying}>
        <Timeline
          sx={sx.timeline}
          currentTime={playedSeconds}
          duration={duration}
          labels={labels}
          labelClassMap={labelClassMap}
          labelClassCategory={labelClassCategory}
          poorQualityClasses={poorQualityClasses}
          tentativeLabels={tentativeLabels}
          latestTentativeStartTime={latestTentativeStartTime}
          furthestViewedTime={furthestViewedTime}
          isPlaying={isPlaying}
          isVideoLabeled={video.status === STATUS.LABELLED}
          isEscOccupied={showConfirmCompleteDialog || showHotkeysDialog}
          onTentativeLabelStart={handleTentativeLabelStart}
          onTentativeLabelEnd={handleTentativeLabelEnd}
          onLabelChangeEnd={handleLabelChangeEnd}
          onLabelClassChange={handleLabelClassChange}
          onLabelDelete={handleLabelDelete}
          onLabelStartTimeChange={handleLabelStartTimeChange}
          onLabelEndTimeChange={handleLabelEndTimeChange}
          onPlay={setIsPlaying}
          onScrub={handleScrub}
        />
      </VideoContextProvider>
      {showConfirmCompleteDialog && (
        <ConfirmCompleteDialog
          onCancel={() => setShowConfirmCompleteDialog(false)}
          onConfirm={onClickComplete}
        />
      )}
    </Grid>
  );
}

export default DetailPage;
