import React from "react";
import Cookies from "js-cookie";
import { ThemeProvider } from "theme-ui";

import Router from "../Router";
import theme from "../theme";
import transformDomain from "../lib/transformDomain";

const Pages = () => {
  // Set cookie domain.
  // NOTE: This is needed because the subdomains for the endo-ml app and
  // SSO are different.
  // For example, in staging:
  // endo-ml.tst-robartsinc.com VS sso.tst-robartsinc.com
  const url = new URL(window.location.href);
  Cookies.set("sso-origin", url.origin, {
    domain: transformDomain(url),
  });

  return (
    <ThemeProvider theme={theme}>
      <Router />
    </ThemeProvider>
  );
};

export default Pages;
