import { identity } from "fp-ts/es6/function";
import { fold } from "fp-ts/es6/Option";
import { pipe } from "fp-ts/lib/function";
import { STATUS } from "../constants";
import { Video, VideoPrime, VideoStatus } from "../datamodel/video";

const transformVideoToPrime = (video: Video): VideoPrime => ({
  ...video,
  userId: pipe(
    video.userId,
    fold(() => null, identity)
  ),
  status:
    video.status === VideoStatus.InProgress
      ? STATUS.INPROGRESS
      : video.status === VideoStatus.Labelled
      ? STATUS.LABELLED
      : STATUS.UNLABELLED,
  completedAt: pipe(
    video.completedAt,
    fold(() => null, identity)
  ),
});

export default transformVideoToPrime;
