import { LabelClass } from "../datamodel/labelClass";

type LabelClassMap = Record<string, string>;

const createLabelClassMap = (labelClasses: LabelClass[]): LabelClassMap =>
  labelClasses.reduce((acc, labelClass) => {
    const key = labelClass.name
      .split(" ")
      .map(name => name.replace(",", "").replace("/", "_").replace("-", "_").toUpperCase())
      .join("_");
    return { ...acc, [key]: labelClass.name };
  }, {} as LabelClassMap);

export default createLabelClassMap;
