import config from "../../config";

const VIEWPORT_SHORT = config.viewportShort;

export default {
  page: {
    gridTemplateColumns: "minmax(5.6rem, 1fr) 64rem 2.4rem minmax(36rem, 45rem) minmax(4rem, 1fr)",
    gridTemplateRows: "2.4rem 12.8rem 12.8rem 20rem 1.6rem 1fr",
    gap: 0,
    gridTemplateAreas: `"back   player  .      .         menu  "
                            ".      player  .      meta      .     "
                            ".      player  .      complete  .     "
                            ".      player  .      playback  .     "
                            ".      .       .      .         .     "
                            "timeline timeline  timeline timeline    timeline"`,
    pt: 1,
    height: "100vh",
    maxHeight: "100vh",
    [VIEWPORT_SHORT]: {
      gridTemplateColumns: "64rem 0.8rem max-content max-content minmax(4rem, 1fr)",
      gridTemplateRows: "5.6rem 11.2rem 1fr",
      gridTemplateAreas: `"player . meta     complete menu    "
                          "player . playback playback playback"
                          "player . timeline timeline timeline"`,
      pt: 0,
    },
  },
  back: {
    gridArea: "back",
    mt: 1,
    mr: "auto",
    ml: 1,
    color: "text",
    fontSize: "3.2rem",
    cursor: "pointer",
    [VIEWPORT_SHORT]: {
      gridArea: "player",
      position: "absolute",
      top: 0,
      left: 0,
      zIndex: 1,
      bg: "#11111133",
      ":hover": {
        bg: "#111111AA",
      },
    },
  },
  menu: {
    gridArea: "menu",
    mt: 1,
    mr: 1,
    ml: "auto",
    zIndex: 1,
    [VIEWPORT_SHORT]: {
      display: "flex",
      flexFlow: "row nowrap",
      justifyContent: "flex-end",
      mx: 0,
      pr: 1,
      borderWidth: "0 0 2px",
      borderStyle: "solid",
      borderColor: "divider",
    },
  },
  meta: {
    gridArea: "meta",
    justifyContent: "space-between",
    alignItems: "center",
    borderWidth: "0 0 2px",
    borderStyle: "solid",
    borderColor: "divider",
    [VIEWPORT_SHORT]: {
      justifyContent: "flex-start",
    },
  },
  fact: {
    mx: 6,
    color: "text",
    [VIEWPORT_SHORT]: {
      flex: "none",
      ml: 2,
      mr: 0,
    },
  },
  factName: {
    fontSize: 1,
    fontWeight: "bold",
    textTransform: "uppercase",
    [VIEWPORT_SHORT]: {
      display: "none",
    },
  },
  factValue: {
    fontFamily: "monospace",
    fontSize: 6,
    [VIEWPORT_SHORT]: {
      fontSize: 2,
      fontWeight: "bold",
    },
  },
  complete: {
    gridArea: "complete",
    justifyContent: "space-around",
    alignItems: "center",
    borderWidth: "0 0 2px",
    borderStyle: "solid",
    borderColor: "divider",
    [VIEWPORT_SHORT]: {
      justifyContent: "flex-start",
    },
  },
  button: {
    flex: "none",
    mx: 3,
    fontSize: 3,
    whiteSpace: "nowrap",
    [VIEWPORT_SHORT]: {
      ml: 4,
      mr: 0,
      px: "1.2rem",
      py: "0.4rem",
      fontSize: 2,
    },
  },
  player: {
    position: "relative",
    gridArea: "player",
  },
  playback: {
    gridArea: "playback",
    borderWidth: "0 0 2px",
    borderStyle: "solid",
    borderColor: "divider",
  },
  timeline: {
    gridArea: "timeline",
  },
};
