import * as t from "io-ts";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";

import { createEnumType } from "../types";

export enum VideoStatus {
  Labelled = "LABELLED",
  UnLabelled = "UNLABELLED",
  InProgress = "INPROGRESS",
}

export const videoStatusCodec = createEnumType<VideoStatus>(VideoStatus, "VideoStatus");

export const videoCodec = t.type({
  id: t.string,
  userId: optionFromNullable(t.string),
  alimentivVideoId: t.string,
  uri: t.string,
  status: videoStatusCodec,
  duration: t.number,
  completedAt: optionFromNullable(t.string),
  createdAt: t.string,
  modifiedAt: t.string,
});

export interface VideoPrime {
  id: string;
  userId: string | null;
  alimentivVideoId: string;
  uri: string;
  status: string;
  duration: number;
  completedAt: string | null;
  createdAt: string;
  modifiedAt: string;
}

export type Video = t.TypeOf<typeof videoCodec>;
