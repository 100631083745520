/** @jsx jsx */
import { jsx, Button, Text } from "theme-ui";
import { useEffect, useRef } from "react";
import { useHotkeys } from "react-hotkeys-hook";

import Dialog from "./Dialog";

const sx = {
  dialog: { width: "40rem" },
  text: {
    mt: 2,
    fontSize: 3,
  },
  confirm: {
    mt: 5,
    p: "1.2rem",
    width: "100%",
    fontWeight: "bold",
  },
  cancel: {
    mt: 5,
    p: "1.2rem",
    width: "100%",
  },
};

function ConfirmCompleteDialog({ onConfirm = () => {}, onCancel = () => {}, ...props }) {
  const confirmRef = useRef();
  useEffect(() => {
    confirmRef.current.focus();
  }, []);

  useHotkeys("esc", e => {
    e.stopPropagation();
    e.preventDefault();
    onCancel();
  });

  return (
    <Dialog title="Mark as complete" sx={sx.dialog} {...props}>
      <Text sx={sx.text}>Be sure you have fully assessed the video.</Text>
      <Button ref={confirmRef} sx={sx.confirm} variant="primary" onClick={onConfirm}>
        Mark video complete
      </Button>
      <Button sx={sx.cancel} variant="secondary" onClick={onCancel}>
        Cancel
      </Button>
    </Dialog>
  );
}

export default ConfirmCompleteDialog;
