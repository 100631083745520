import * as t from "io-ts";

import { createEnumType } from "../types";

export enum LabelClassGroup {
  PoorQuality = "POOR_QUALITY",
  Lesions = "LESIONS",
  OtherDisease = "OTHER_DISEASE",
  OtherDescriptors = "OTHER_DESCRIPTORS",
}

export const labelClassGroupCodec = createEnumType<LabelClassGroup>(
  LabelClassGroup,
  "LabelClassGroup"
);

export const labelClassCodec = t.type({
  id: t.string,
  classGroup: labelClassGroupCodec,
  name: t.string,
  ord: t.number,
  createdAt: t.string,
  modifiedAt: t.string,
});

export type LabelClass = t.TypeOf<typeof labelClassCodec>;
