import React from "react";

interface VideoContext {
  isPlaying: boolean;
}

export const {
  Provider: VideoContextProvider,
  Consumer: VideoContextConsumer,
} = React.createContext<VideoContext>({
  isPlaying: false,
});
