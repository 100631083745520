import * as t from "io-ts";
import { AxiosResponse } from "axios";
import { Label, LabelCreate, labelCreateCodec, labelCodec } from "../datamodel/label";
import customAxios from "./customAxios";
import { decodeAxiosResponse } from "./decodeAxiosResponse";

export const listLabels = async (videoId: string): Promise<AxiosResponse<Label[]>> =>
  decodeAxiosResponse(t.array(labelCodec))(await customAxios.get(`/api/videos/${videoId}/labels`));

export const postLabel = async (
  videoId: string,
  labelCreate: LabelCreate
): Promise<AxiosResponse<Label>> =>
  decodeAxiosResponse(labelCodec)(
    await customAxios.post(`/api/videos/${videoId}/labels`, labelCreateCodec.encode(labelCreate))
  );

export const updateLabel = async (
  videoId: string,
  labelId: string,
  labelCreate: LabelCreate
): Promise<AxiosResponse<"">> =>
  await customAxios.put(
    `/api/videos/${videoId}/labels/${labelId}`,
    labelCreateCodec.encode(labelCreate)
  );

export const deleteLabel = async (videoId: string, labelId: string): Promise<AxiosResponse<"">> =>
  await customAxios.delete(`/api/videos/${videoId}/labels/${labelId}`);
